@import '../general/variables.scss';

.lots-wrapper {
  .top-header {
    @include top-header;
    .auction-live-date {
      .status {
        display: inline-block;
        padding-left: 32px;
        @media (max-width: 605px) {
          display: block;
          padding-left: 0;
          margin-top: 4px;
        }
        &-open {
          color: #fac917;
        }
        &-live {
          color: #1f9512;
        }
      }
    }
  }
  .top-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &.upcoming {
      justify-content: space-between;
    }
    .left, .right {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
    .btn {
      @include basic-button;
      flex-shrink: 0;
      margin: 0 10px 10px 0;
      @media (max-width: $mobile-width) {
        margin-right: 0;
        width: 100%;
      }
      &.enter-auction-btn {
        color: #fff;
        background-color: #fac917;
        margin-right: 0;
      }
    }
    &.results {
      border-bottom: 1px solid #c6c6c6;
    }
  }
  .table-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 25px 0;
    padding: 40px 25px;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    & > div,
    & > span {
      font-family: Lato;
      font-size: 16px;
      display: flex;
      padding: 5px;
      & > div {
        width: 20%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      & > div:first-child {
        width: 60%;
        min-width: 130px !important;
        padding-right: 15px;
      }
    }
    .table-title {
      background-color: #666666;
      padding: 15px;
      min-width: 640px;
      justify-content: center;
      font-family: Lato;
      font-size: 14px;
      color: #fff;
      margin: 0 5px;
    }

    .table-header, .table-footer, .table-row {
      font-weight: bold;

      div {
        padding: 15px;
        font-family: Lato;
        font-size: 14px;
      }
    }

    .table-header, .table-footer {
      div {
        margin: 1px;
        border: 1px solid #666;
        background-color: #c6c6c6;
        color: #4d4e4e;
      }
    }

    .table-row {
      padding-top: 0;
      padding-bottom: 0;

      &.single-row {

        div {
          min-width: 100px;
          border: 1px solid #c6c6c6;
        }
      }

      &:not(.single-row) {

        &:first-of-type {

          div {
            border: 1px solid #c6c6c6;
            border-bottom: none;
          }
        }

        &:not(:first-of-type),
        &:not(:last-of-type) {

          div {
            border-left: 1px solid #c6c6c6;
            border-right: 1px solid #c6c6c6;
          }
        }

        &:last-of-type {

          div {
            border: 1px solid #c6c6c6;
            border-top: none;
          }
        }
      }

      div {
        margin: 0 1px;
        background-color: #ededed;
        color: #030404;
      }
    }

    &.table-auction-results {
      overflow-x: scroll;
      display: inline-flex;
      span {
        &.table-row {
          div {
            min-width: 100px;
          }
        }
      }
      @media (max-width: 700px) {
        padding-left: 0;
        padding-right: 0;
      }
      div.table-header,
      div.table-row,
      div.table-footer {
        div {
          min-width: 100px;
        }
      }
    }
  }

  .lots-controls {
    padding: 10px 0;
    border-top: 1px solid $gray-border;
    border-bottom: 1px solid $gray-border;

    .filters-heading {
      margin: 0;
      color: #5d5d5d;
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
    }

    .lots-filters-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @media (min-width: $mobile-width) {
        flex-direction: row;
      }

      @media (min-width: $large-width) {
        justify-content: space-between;
      }
    }

    .filter-btn {
      @include basic-button;
      @include filter-button;
      margin-top: 10px;
      position: initial;
    }
  }

  .catalog-settings {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    & > * {
      flex: 1;
    }
    .catalog-header {
      align-self: flex-end;
      margin: 0;
      font: 400 24px Lato;
      color: #5d5d5d;
      text-transform: uppercase;
      text-align: center;
    }

    .view-modes {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .view-mode-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 45px;
        height: 45px;
        background-color: #fff;
        border: 1px solid #464646;
        border-radius: 5px;
        cursor: pointer;
        overflow: hidden;
        @media (max-width: $medium-width) {
          display: none;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          transform: scale(1.1);
          background-size: cover;
          background-position: center;
        }
        &.view-mode-list:before {
          background-image: url("./assets/img/view-list.png");
        }
        &.view-mode-grid:before {
          background-image: url("./assets/img/view-grid.png");
        }
        &.active {
          background-color: #1f9512;
          &:before {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

.lots {
  display: flex;
  justify-content: space-between;
  @media (max-width: $medium-width) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left-side {
    width: 100%;
  }
}

.lot-info-wrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: $medium-width) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .left-side {
    width: 100%;
  }
  .calculator {
    margin-top: 20px !important;
  }
}

.lots-list, .watch-lots {
  display: flex;
  flex-direction: column;
  position: relative;
  zoom: 80%;
  margin-top: 25px;
  p {
    margin: 0;
  }
  &.results {
    .lot-item {
      &.lot-item-withdrawn {
        .lot-info-middle {
          background-color: #626262;
        }
      }
    }
  }
  .lot-item {
    display: flex;
    position: relative;
    margin-bottom: 25px;
    background-color: #fff;
    //border: 1px solid $gray-border;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #2e2e2e;
    .lot-item-top {
      .lot-info {
        .lot-info-bottom {
          @media (max-width: 550px) {
            justify-content: flex-start;
            align-items: flex-start;
          }
          .info-wrap {
            @media (max-width: 550px) {
              justify-content: flex-start;
              align-items: flex-start;
            }
          }
        }
      }
    }
    .lot-item-bottom {
      .lot-info {
        .lot-info-bottom {
          @media (max-width: 550px) {
            align-items: flex-start;
          }
          .info-wrap {
            @media (max-width: 550px) {
              align-items: flex-start;
            }
          }
          .info-btns {
            width: 192px;
            justify-content: flex-end;
            a:first-child {
              margin-bottom: 5px;
              margin-right: 0 !important;
              @media (max-width: 550px) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    &.dump {
      border: 0;
      box-shadow: none;
      background-color: transparent;
    }

    &.lot-item-withdrawn {
      background-color: #626262;
      .lot-info {
        background-color: $light-background;
        .lot-info-middle {
          min-height: auto;
          border-bottom: 0;
          .lot-title {
            text-decoration: line-through;
            margin: 0;
          }
        }
      }
    }

    &.existing {
      display: flex;
      flex: 1;
      flex-direction: column;
      .lot-item-top {
        flex: 1;
        display: flex;
        position: relative;
      }
      .lot-item-bottom {
        flex: 1;
        border-top: 1px solid #c6c6c6;
      }
    }

    .lot-img-container {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: 240px;

      @media (max-width: $medium-width) {
        width: 100%;
      }
    }

    .lot-info-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 9px 16px;
      color: #fff;
      background-color: #2e2e2e;
      font: 100 16px Lato;
      .lot-number {
        .my-listing {
          padding-left: 5px;
          .icon-wrapper {
            position: relative;
            top: -2px;
            padding: 0 5px;
          }
        }
      }
      .lot-location {
        text-transform: uppercase;
        text-align: right;
      }
    }

    .lot-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-family: Lato;
      .lot-info-middle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0;
        min-height: 100px;
        padding: 5px 10px 8px 20px;
        border-bottom: 1px solid $gray-border;

        @media (min-width: $mobile-width) {
          flex-direction: row;
        }
        .left {
          // max-width: 500px;
          padding-right: 16px;
          max-width: fit-content;
          .lot-title {
            margin-top: 0;
          }
        }
        .lot-title-wrapper {
          display: flex;
          justify-content: space-between;
          min-width: 275px;
          width: 100%;
          margin-bottom: 16px;
        }
        .lot-sub-title {
          min-width: 125px;
          color: #2e2e2e;
          font-size: 18px;
          margin: 8px 0 8px;
        }
        .curfew {
          color: #e6bd2f;
        }
        .lot-title {
          color: #2e2e2e;
          font-size: 18px;
          min-height: 0px;
          margin: 8px 0 12px !important;
        }
        .lot-closed-state-container {
          margin-bottom: 24px;
        }
        .lot-closed-state {
          display: block;
          font-family: Lato;
          font-size: 17px;
          font-weight: 400;
          color: #dd2d2e;
          margin-bottom: 25px;

          &.lot-closed-success, &.sold {
            color: #1f9512;
          }

          &.passed {
            color: #f9891a;
          }
        }
        .lot-comments {
          word-break: break-word;
          margin-top: 12px;
          color: #696969;
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          font-size: 12px;
          cursor: pointer;
          .lot-readMore {
            color: #e6bd2f;
            font-size: 12px;
            text-decoration: none;
            cursor: pointer;
          }
        }
        .info-btns {
          margin-bottom: 8px;
          & > *:not(:last-child) {
            margin-bottom: 8px;
          }
          .btn {
            @include basic-button;
            width: 184px;
            height: 35px;
          }
        }
      }
      .lot-info-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px;

        @media (min-width: $mobile-width) {
          flex-direction: row;
        }

        .info-wrap {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          @media (min-width: $mobile-width) {
            justify-content: flex-start;
          }

          span {
            display: inline-block;
            line-height: 35px;
            padding: 0 7px;
            border-radius: 5px;
            border: 1px solid #5d5d5d;
            color: #5d5d5d;
            font-family: Lato;
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 5px;

            @media (min-width: $large-width) {
              margin-bottom: 0;
              justify-content: flex-start;
            }

            &:not(:last-child) {
              margin-right: 5px;
            }
          }
          .optiweigh-logo-wrapper {
            display: flex;
            img {
              align-self: center;
              max-height: 25px;
              margin-bottom: 10px;
            }
            @media (max-width: 1035px) {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .company-logo-wrapper {
          img {
            max-height: 40px;
          }
        }
      }
    }
  }
}

@media (min-width: $medium-width) {
  .lots-list.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .lot-item {
      display: flex;
      flex-direction: column;
      width: 32%;
      height: 783px;
      .lot-img-container {
        width: 100%;
        height: 250px;
      }
      .lot-info {
        .lot-info-middle {
          padding: 9px 16px;
          flex-direction: column;
          .left {
            padding: 0;
            .lot-title {
              height: 80px;
              text-align: center;
              margin-top: 0;
            }
          }
          .info-btns {
            display: flex;
            flex-direction: row;
            margin: 10px 0;
            & > *:not(:last-child) {
              margin-bottom: 0;
              margin-right: 5px;
            }
          }
        }
        .lot-info-bottom {
          flex-direction: column;
          padding: 0;

          .info-wrap {
            width: 100%;
            justify-content: center;
            padding: 12px 16px 8px 16px;
            border-bottom: 1px solid #c6c6c6;
            span {
              margin-bottom: 4px;
            }
          }
          .company-logo-wrapper {
            height: 64px;
            padding: 12px 0;
          }
        }
      }
    }
  }
}

@media (max-width: $large-width) and (min-width: $medium-width) {
  .lots-list.grid {
    .lot-item {
      width: 49%;
    }
    &.dump {
      display: none;
    }
  }
}

@media (max-width: 1100px) and (min-width: $medium-width) {
  .lots-list.grid {
    .lot-item {
      height: 681px;
      width: 49%;
    }
  }
}

@media (max-width: $medium-width) {
  .lots-wrapper {
    .catalog-settings {
      flex-direction: column;
      .catalog-header {
        margin-top: 10px;
        align-self: center;
      }
    }
  }
  .lots-list, .watch-lots {
    .lot-item {
      display: block;
      &.existing {
        .lot-item-top {
          display: block;
        }
      }
      .lot-img-container {
        height: 270px;
        &-withdrawn {
          height: 100px;
        }
      }
    }
  }
}
