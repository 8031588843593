@import "./themes/general/variables.scss";

@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MyriadPro-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Myriad Pro';
  src: url('./assets/fonts/MyriadPro-Bold.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Thin.woff2');
  font-weight: 100;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Bold.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Black.woff2');
  font-weight: 900;
}
* {
  box-sizing: border-box;
  outline: none;
}
html, body {
  margin: 0;
  height: 100%;
  #root {
    height: 100%;
    &>div {
      position: relative;
      height: 100%;
    }
  }
}
body {
  min-width: 320px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@import "./assets/normalize/normalize.css";
@import "./themes/general/general.scss";
@import "./themes/general/dropdowns.scss";
@import "./themes/upcoming-results/main.scss";
@import "./themes/upcoming-results/AuctionsList.scss";
@import "./themes/upcoming-results/LotsList.scss";
@import "./themes/upcoming-results/LotDetails.scss";
// @import "../routes/Routes.scss";

// @import "Notifications/Notifications.scss";
@import "./components/BackButton/BackButton.scss";
// @import "Header/Header.scss";
// @import "AuctionLive/LiveHeader/LiveHeader.scss";
// @import "Footer/Footer.scss";
// @import "NavBar/NavBar.scss";
@import "./components/Carousel/Carousel.scss";
@import "./components/DetailedInfo/DetailedInfo.scss";
// @import "StateTimerAuction/StateTimerAuction.scss";
// @import "StateTimerLot/StateTimerLot.scss";
@import "./components/Modal/Modal.scss";
@import "./components/WatchButton/WatchButton.scss";
// @import "FormField/FormField.scss";
// @import "Checkbox/Checkbox.scss";
// @import "CheckboxWithValue/CheckboxWithValue.scss";
// @import "RadioButton/RadioButton.scss";
@import "./components/SortDropdown/SortDropdown.scss";
// @import "SelectDropdown/SelectDropdown.scss";
// @import "FgSelect/FgSelect.scss";
// @import "FgTable/FgTable.scss";
// @import "FgProgressBar/FgProgressBar.scss";
@import "./themes/FilterDate.scss";
@import "./components/FilterMultiSelect/FilterMultiSelect.scss";
@import "./components/FilterRange/FilterRange.scss";
@import "./components/FilterRangeAge/FilterRangeAge.scss";
@import "./components/FilterKind/FilterKind.scss";
// @import "LotForm/LotForm.scss";
// @import "LotForm/parts/styles.scss";

// @import "Auth/Auth.scss";
// @import "Auth/Fd-input/input.scss";
// @import "AuctionLive/AuctionLive.scss";
// @import "Auth/AuthCheckbox/AuthCheckbox.scss";
// @import "Auth/NavigationTop/NavigationTop.scss";
// @import "AuctionLive/RenderLots/RenderLots.scss";
// @import "AuctionLive/RenderLots/RenderLot/RenderLot.scss";
// @import "AuctionLive/Bidding/Bidding.scss";
// @import "AuctionLive/LotInfo/LotInfo.scss";
// @import "Profile/Profile.scss";

// @import "MainApp/MainApp.scss";
// @import "ManageLots/ManageLots.scss";
// @import "Tools/Tools.scss";
// @import "Tools/Manuals/Manuals.scss";
// @import "Tools/ListingFees/ListingFees.scss";
// @import "Tools/Policy/Policy.scss";
// @import "Tools/Search/Search.scss";
// @import "Tools/Videos/Videos.scss";
// @import "Tools/Faqs/Faqs.scss";
// @import "Tools/Section/Section.scss";
// @import "Tools/Terms/Terms.scss";
// @import "ManageLots/AddLot/AddLot.scss";
// @import "ManageLots/ExistingLotsList/ExistingLotsList.scss";
// @import "ManageLots/MyFavouritePics/MyFavouritePics.scss";
// @import "ManageLots/MyResults/MyResults.scss";
// @import "ManageLots/MyListings/MyListings.scss";
// @import "ChangeForgottenPassword/ChangeForgottenPassword.scss";
// @import "Watchlist/Watchlist.scss";
@import "./themes/WatchlistInfo.scss";
// @import "NotFound/NotFound.scss";
@import "./components/LotDocuments/LotDocuments.scss";
// @import "ContactUs/ContactUs.scss";
@import "./components/DownloadResultBtn/DownloadResultBtn.scss";

@import "../node_modules/react-dropdown/style.css";
// @import "../../node_modules/react-toggle/style.css";
// @import "../../node_modules/react-day-picker/lib/style.css";
@import "../node_modules/rc-slider/assets/index.css";
// @import "../../node_modules/react-table/react-table.css";
