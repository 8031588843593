/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
@import url(./assets/normalize/normalize.css);
@import url(./themes/general/variables.css);
@import url(./themes/general/variables.css);
@import url(../node_modules/react-dropdown/style.css);
@import url(../node_modules/rc-slider/assets/index.css);
@font-face {
  font-family: 'Myriad Pro';
  src: url("./assets/fonts/MyriadPro-Regular.otf");
  font-weight: 400; }

@font-face {
  font-family: 'Myriad Pro';
  src: url("./assets/fonts/MyriadPro-Bold.otf");
  font-weight: 700; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Thin.woff2");
  font-weight: 100; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Regular.woff2");
  font-weight: 400; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Bold.woff2");
  font-weight: 700; }

@font-face {
  font-family: 'Lato';
  src: url("./assets/fonts/Lato-Black.woff2");
  font-weight: 900; }

* {
  box-sizing: border-box;
  outline: none; }

html, body {
  margin: 0;
  height: 100%; }
  html #root, body #root {
    height: 100%; }
    html #root > div, body #root > div {
      position: relative;
      height: 100%; }

body {
  min-width: 320px; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
html {
  height: 100%; }

body {
  min-height: 100%; }

.inner-container {
  position: relative;
  background-color: white;
  max-width: 1280px;
  width: 100%;
  min-height: 1020px;
  margin: auto;
  background-color: #ededed;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
  padding: 40px 50px; }
  @media (max-width: 900px) {
    .inner-container {
      padding: 25px 10px;
      max-width: 800px; } }
  @media (max-width: 900px) {
    .inner-container {
      padding: 25px 10px;
      max-width: 800px; } }
  @media (max-width: 800px) {
    .inner-container {
      padding: 25px 10px;
      max-width: 700px; } }
  @media (max-width: 700px) {
    .inner-container {
      padding: 25px 10px;
      max-width: 600px; } }
  @media (max-width: 600px) {
    .inner-container {
      padding: 25px 10px;
      max-width: 500px; } }
  @media (max-width: 550px) {
    .inner-container {
      padding: 25px 10px;
      max-width: 400px; } }
  @media (max-width: 400px) {
    .inner-container {
      max-width: 350px; } }
  @media (max-width: 350px) {
    .inner-container {
      max-width: 300px; } }
  @media (max-width: 300px) {
    .inner-container {
      max-width: 250px; } }

.body-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 40px 5px;
  background-color: #cccccc;
  clear: both; }
  .body-container .inner-container {
    position: relative;
    background-color: white;
    max-width: 1280px;
    width: 100%;
    min-height: 1020px;
    margin: auto;
    background-color: #ededed;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
    padding: 40px 50px; }
    @media (max-width: 900px) {
      .body-container .inner-container {
        padding: 25px 10px; } }
  @media (max-width: 900px) {
    .body-container {
      padding: 20px 0; } }

.no-image {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: grey;
  width: 100%;
  height: 100%;
  font: normal 16px 'Lato';
  color: #fff; }
  .no-image:before {
    content: '';
    background: url("./assets/img/icon_photo.png") no-repeat;
    background-size: cover;
    display: flex;
    width: 53px;
    height: 40px;
    margin-bottom: 10px; }
  .no-image.cattle:before {
    background: url("./assets/img/cow.png") no-repeat; }
  .no-image.sheep:before {
    background: url("./assets/img/sheep.png") no-repeat; }
  .no-image.goat:before {
    background: url("./assets/img/goat-image-middle.png") no-repeat; }

.separator {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #c6c6c6;
  margin: 0; }

.app-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7); }
  .app-spinner-overlay.area {
    position: absolute; }
  .app-spinner-overlay .app-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .app-spinner-overlay .app-spinner.area {
      position: absolute; }

.remove {
  width: 20px;
  height: 20px;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer; }
  .remove:after {
    width: 20px;
    height: 20px;
    content: '';
    background: url("./assets/img/closebutton.png") no-repeat;
    background-size: cover;
    display: block; }

button:focus, label:focus {
  box-shadow: 0 0 2px 2px #a3a3b1; }

.disabled {
  opacity: 0.6;
  pointer-events: none; }

.fg-header {
  color: #030404;
  font-family: Lato;
  font-size: 40px;
  font-weight: 400;
  margin: 0 0 20px 0; }
  @media (max-width: 550px) {
    .fg-header {
      font-size: 28px; } }
  .fg-header.watch-header {
    margin-bottom: 10px; }

.fg-secondary-header {
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 300;
  color: #545757;
  font-family: 'Lato'; }

.fg-sub-header {
  color: #030404;
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 26px; }

.register-interest-text {
  color: #030404;
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 26px;
  text-align: center;
  margin-top: 40px; }

.register-interest-div {
  display: table;
  margin: 0 auto; }

.register-interest-button {
  font: 400 16px Lato;
  padding: 10px 10px;
  border-radius: 5px;
  color: white;
  background-color: #5D5D5D;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .register-interest-button .icon-wrapper {
    padding-left: 12px; }

.register-interest-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .register-interest-form label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 380px;
    margin-bottom: 10px; }
  .register-interest-form input {
    width: 350px;
    height: 36px; }
  .register-interest-form .error {
    color: #ff0000; }
  .register-interest-form button {
    align-self: center; }
  .register-interest-form .auth-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 197px;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #1f9512;
    transition: 400ms;
    cursor: pointer; }
    .register-interest-form .auth-button-success {
      background-color: #1f9512; }
      .register-interest-form .auth-button-success:hover {
        background-color: #2daf1f; }
      .register-interest-form .auth-button-success:disabled {
        background-color: #666; }
    .register-interest-form .auth-button-alert {
      background-color: #fac917; }
      .register-interest-form .auth-button-alert:hover {
        background-color: #ebb111; }
    .register-interest-form .auth-button.btn-text-centered span {
      padding: 0;
      text-align: center; }
    .register-interest-form .auth-button span {
      flex: 1;
      color: #fff;
      text-align: left;
      padding-left: 12px;
      font-family: "Myriad Pro";
      font-size: 18px;
      font-weight: 400; }
    .register-interest-form .auth-button i {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      width: 43px;
      border-radius: 3px; }
      .register-interest-form .auth-button i.btn-right {
        border-left: 1px solid #000; }
      .register-interest-form .auth-button i.btn-left {
        border-right: 1px solid #000; }
      .register-interest-form .auth-button i svg {
        font-size: 38px;
        color: #fff; }
  .register-interest-form .btn-text-centered span {
    padding: 0;
    text-align: center; }

.fg-paragraph {
  color: #030404;
  font: 400 16px Lato; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.app-route {
  position: relative;
  z-index: 3; }

.no-data-placeholder {
  margin-top: 20px;
  text-align: center; }
  .no-data-placeholder .message {
    display: flex;
    justify-content: center;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400; }

.ReactModalPortal-Email .overlay {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 800;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-height: 500px) {
    .ReactModalPortal-Email .overlay {
      overflow-y: scroll; } }
  .ReactModalPortal-Email .overlay .content {
    display: flex;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    width: 60%;
    flex-direction: column;
    align-items: center;
    border: 1px solid #cccccc;
    background: white;
    overflow: hidden;
    outline: none;
    font-family: 'Lato'; }
    @media (max-width: 500px) {
      .ReactModalPortal-Email .overlay .content {
        width: 90%; } }
    @media (min-width: 1200px) {
      .ReactModalPortal-Email .overlay .content {
        max-width: 700px; } }
    @media (max-height: 500px) {
      .ReactModalPortal-Email .overlay .content {
        margin-top: 90px;
        margin-bottom: 30px; } }
    @media (max-height: 350px) {
      .ReactModalPortal-Email .overlay .content {
        margin-top: 150px;
        margin-bottom: 30px; } }
    @media (max-height: 390px) {
      .ReactModalPortal-Email .overlay .content {
        max-height: 420px; } }
    .ReactModalPortal-Email .overlay .content .icon-danger {
      color: #dd2d2e; }
    .ReactModalPortal-Email .overlay .content .icon-close {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 44px;
      width: 44px;
      right: -1px;
      top: -1px;
      font-size: 22px;
      border-radius: 5px;
      border: 1px solid #cccccc;
      cursor: pointer; }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .icon-close {
          height: 30px;
          width: 30px; } }
    .ReactModalPortal-Email .overlay .content .modal-header {
      text-align: center; }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .modal-header {
          font-size: 18px; } }
    .ReactModalPortal-Email .overlay .content .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      width: 60%; }
      @media (max-width: 450px) {
        .ReactModalPortal-Email .overlay .content .modal-body {
          margin-top: 0; } }
      @media (max-height: 390px) {
        .ReactModalPortal-Email .overlay .content .modal-body {
          margin-top: 0; } }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .modal-body {
          width: 100%; } }
      .ReactModalPortal-Email .overlay .content .modal-body.download {
        margin-top: 0; }
        @media (max-width: 800px) {
          .ReactModalPortal-Email .overlay .content .modal-body.download {
            width: 100%; } }
      .ReactModalPortal-Email .overlay .content .modal-body form {
        width: 100%; }
      .ReactModalPortal-Email .overlay .content .modal-body .modal-catalogue-title {
        font-size: 20px;
        text-align: center; }
    .ReactModalPortal-Email .overlay .content .modal-input ~ .modal-input {
      margin-top: 30px; }
    .ReactModalPortal-Email .overlay .content .modal-input {
      display: block;
      width: 100%;
      min-height: 45px;
      border-radius: 5px;
      background-color: #666666;
      color: #ffffff;
      font: 100 18px Lato;
      line-height: 30px;
      transition: background-color .5s ease;
      padding-left: 15px;
      border: none; }
      .ReactModalPortal-Email .overlay .content .modal-input:focus {
        background-color: rgba(102, 102, 102, 0.8); }
      .ReactModalPortal-Email .overlay .content .modal-input::placeholder {
        color: #ffffff; }
    .ReactModalPortal-Email .overlay .content .choice-desc {
      text-align: left; }
      @media (max-width: 800px) {
        .ReactModalPortal-Email .overlay .content .choice-desc {
          margin-bottom: 0;
          font-size: 14px; } }
      @media (max-width: 400px) {
        .ReactModalPortal-Email .overlay .content .choice-desc {
          max-width: 250px;
          margin-top: 20px; } }
    .ReactModalPortal-Email .overlay .content .buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 50px; }
      @media (max-width: 450px) {
        .ReactModalPortal-Email .overlay .content .buttons {
          margin-bottom: 0; } }
      @media (min-width: 1348px) {
        .ReactModalPortal-Email .overlay .content .buttons .button {
          flex: 1 1;
          min-width: 213px !important; } }
      .ReactModalPortal-Email .overlay .content .buttons .button {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 231px;
        height: 44px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #000000;
        text-align: left;
        color: #030404;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        transition: box-shadow 300ms;
        padding-left: 20px;
        text-decoration: none;
        overflow: hidden;
        user-select: none;
        margin-top: 30px;
        width: 100%;
        border-radius: 3px;
        color: #ffffff;
        background-color: #1f9512; }
        .ReactModalPortal-Email .overlay .content .buttons .button:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
        .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 44px;
          height: 100%;
          font-size: 22px;
          border-radius: 5px 0 0 5px;
          border-left: 1px solid #000000;
          color: #fff; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-green {
            color: #1f9512 !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-success {
            background-color: #1f9512 !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-warning {
            background-color: #fac917 !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-danger {
            color: #dd2d2e !important; }
          .ReactModalPortal-Email .overlay .content .buttons .button .icon-wrapper.icon-red {
            background-color: #dd2d2e !important; }
        .ReactModalPortal-Email .overlay .content .buttons .button.ok-button {
          display: flex;
          justify-content: center; }
        .ReactModalPortal-Email .overlay .content .buttons .button.red-button {
          background-color: #dd2d2e; }
        .ReactModalPortal-Email .overlay .content .buttons .button .button-text {
          color: #ffffff;
          font-family: Lato;
          font-size: 14px;
          font-weight: 400;
          padding-right: 20px; }
        @media (max-height: 390px) {
          .ReactModalPortal-Email .overlay .content .buttons .button {
            margin-top: 10px; } }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.auctions-wrapper {
  position: relative;
  background-color: #ededed; }
  .auctions-wrapper .red {
    color: red; }
  .auctions-wrapper .auctions-list {
    margin-top: 20px; }
    .auctions-wrapper .auctions-list .auctions-group .group-title {
      color: #000000;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      font-weight: normal;
      font-size: 25px;
      text-align: center;
      margin-bottom: 25px;
      color: #5d5d5d; }
    .auctions-wrapper .auctions-list .auctions-empty {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      margin-top: 60px;
      color: #ADB5BD; }
      .auctions-wrapper .auctions-list .auctions-empty svg {
        font-size: 60px; }
      .auctions-wrapper .auctions-list .auctions-empty p {
        font-size: 30px;
        margin-top: 10px;
        font-weight: 400; }
    .auctions-wrapper .auctions-list .locked-item {
      position: relative;
      overflow: hidden;
      pointer-events: none;
      cursor: none;
      margin-bottom: 25px; }
      .auctions-wrapper .auctions-list .locked-item .result {
        position: absolute;
        width: 117%;
        left: -8%;
        height: 125px;
        font-size: 40px;
        font-weight: 300;
        z-index: 10;
        top: calc(50% - 65px);
        transform: rotate(-16deg);
        background: rgba(22, 22, 22, 0.4);
        color: #ffffff;
        text-transform: uppercase;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato; }
      .auctions-wrapper .auctions-list .locked-item .auction-item {
        margin: 0;
        filter: brightness(80%); }
    .auctions-wrapper .auctions-list .auction-item {
      display: flex;
      position: relative;
      border: 1px solid #c6c6c6;
      margin-bottom: 25px;
      border-radius: 5px;
      background-color: #ffffff;
      overflow: hidden; }
      .auctions-wrapper .auctions-list .auction-item .auction-img-container {
        min-width: 320px;
        max-width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border-right: 1px solid #c6c6c6; }
        .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fac917; }
          .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper > img {
            filter: brightness(0.01) invert(100%); }
          .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn {
            color: #fff;
            font-family: Lato;
            font-size: 24px;
            font-weight: 400;
            border-color: #fff;
            margin-bottom: 0;
            background-color: transparent;
            padding: 0; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn span:first-child {
              margin: 0 auto; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn .icon-wrapper {
              border-color: #fff; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper .enter-auction-btn:hover {
              box-shadow: 0 0 5px rgba(255, 255, 255, 0.16), 0 0 5px rgba(255, 255, 255, 0.3); }
          .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper-open {
            background-color: #535353; }
            .auctions-wrapper .auctions-list .auction-item .auction-img-container .enter-auction-wrapper-open .enter-auction-btn {
              font-size: 20px; }
        .auctions-wrapper .auctions-list .auction-item .auction-img-container .auction-img {
          max-width: 100%;
          max-height: 100%;
          user-select: none; }
        .auctions-wrapper .auctions-list .auction-item .auction-img-container .no-image {
          min-height: 300px; }
      .auctions-wrapper .auctions-list .auction-item .auction-live-info {
        text-transform: uppercase;
        color: #1f9512;
        font-family: Lato;
        font-size: 20px;
        font-weight: 700; }
  .auctions-wrapper .scroll-loader-wrap {
    visibility: hidden;
    text-align: center; }
    .auctions-wrapper .scroll-loader-wrap.active {
      visibility: visible; }
    .auctions-wrapper .scroll-loader-wrap .scroll-loader {
      display: inline-block; }

.auctions-wrapper .btn, .watchlist-wrapper .btn, .submitted-wrapper .btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 231px;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: left;
  color: #030404;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: box-shadow 300ms;
  padding-left: 20px;
  text-decoration: none;
  overflow: hidden;
  user-select: none; }
  .auctions-wrapper .btn:hover, .watchlist-wrapper .btn:hover, .submitted-wrapper .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
  .auctions-wrapper .btn .icon-wrapper, .watchlist-wrapper .btn .icon-wrapper, .submitted-wrapper .btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff; }
    .auctions-wrapper .btn .icon-wrapper.icon-green, .watchlist-wrapper .btn .icon-wrapper.icon-green, .submitted-wrapper .btn .icon-wrapper.icon-green {
      color: #1f9512 !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-success, .watchlist-wrapper .btn .icon-wrapper.icon-success, .submitted-wrapper .btn .icon-wrapper.icon-success {
      background-color: #1f9512 !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-warning, .watchlist-wrapper .btn .icon-wrapper.icon-warning, .submitted-wrapper .btn .icon-wrapper.icon-warning {
      background-color: #fac917 !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-danger, .watchlist-wrapper .btn .icon-wrapper.icon-danger, .submitted-wrapper .btn .icon-wrapper.icon-danger {
      color: #dd2d2e !important; }
    .auctions-wrapper .btn .icon-wrapper.icon-red, .watchlist-wrapper .btn .icon-wrapper.icon-red, .submitted-wrapper .btn .icon-wrapper.icon-red {
      background-color: #dd2d2e !important; }
  .auctions-wrapper .btn:not(:first-of-type), .watchlist-wrapper .btn:not(:first-of-type), .submitted-wrapper .btn:not(:first-of-type) {
    margin-top: 10px; }
  .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
    margin: 0;
    padding-left: 10px;
    width: 231px; }
    .auctions-wrapper .btn.filter-btn:hover, .watchlist-wrapper .btn.filter-btn:hover, .submitted-wrapper .btn.filter-btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    @media (max-width: 550px) {
      .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
        width: 100%; } }
    @media (min-width: 551px) {
      .auctions-wrapper .btn.filter-btn:not(:last-of-type), .watchlist-wrapper .btn.filter-btn:not(:last-of-type), .submitted-wrapper .btn.filter-btn:not(:last-of-type) {
        margin-right: 10px; } }
    .auctions-wrapper .btn.filter-btn:hover:not(.btn-opened), .watchlist-wrapper .btn.filter-btn:hover:not(.btn-opened), .submitted-wrapper .btn.filter-btn:hover:not(.btn-opened) {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
      .auctions-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper, .watchlist-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper, .submitted-wrapper .btn.filter-btn:hover:not(.btn-opened) .icon-wrapper {
        background-color: #fac917; }
    .auctions-wrapper .btn.filter-btn .icon-wrapper, .watchlist-wrapper .btn.filter-btn .icon-wrapper, .submitted-wrapper .btn.filter-btn .icon-wrapper {
      transition: 200ms;
      color: #5d5d5d; }
      @media (min-width: 550px) and (max-width: 900px) {
        .auctions-wrapper .btn.filter-btn .icon-wrapper, .watchlist-wrapper .btn.filter-btn .icon-wrapper, .submitted-wrapper .btn.filter-btn .icon-wrapper {
          margin-left: 10px; } }
    @media (min-width: 550px) {
      .auctions-wrapper .btn.filter-btn-sm, .watchlist-wrapper .btn.filter-btn-sm, .submitted-wrapper .btn.filter-btn-sm {
        max-width: 120px; }
      .auctions-wrapper .btn.filter-btn-md, .watchlist-wrapper .btn.filter-btn-md, .submitted-wrapper .btn.filter-btn-md {
        max-width: 155px; }
      .auctions-wrapper .btn.filter-btn-lg, .watchlist-wrapper .btn.filter-btn-lg, .submitted-wrapper .btn.filter-btn-lg {
        max-width: 230px; } }
    .auctions-wrapper .btn.filter-btn-active:not(.btn-opened), .watchlist-wrapper .btn.filter-btn-active:not(.btn-opened), .submitted-wrapper .btn.filter-btn-active:not(.btn-opened) {
      border-color: #1f9512; }
    .auctions-wrapper .btn.filter-btn-active .icon-wrapper,
    .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper,
    .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper, .submitted-wrapper .btn.filter-btn-active .icon-wrapper,
    .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper {
      background-color: #1f9512;
      border-color: #1f9512; }
      .auctions-wrapper .btn.filter-btn-active .icon-wrapper > svg,
      .auctions-wrapper .btn.filter-btn-active .icon-wrapper > img,
      .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg,
      .auctions-wrapper .btn.filter-btn-active:hover .icon-wrapper > img, .watchlist-wrapper .btn.filter-btn-active .icon-wrapper > svg,
      .watchlist-wrapper .btn.filter-btn-active .icon-wrapper > img,
      .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg,
      .watchlist-wrapper .btn.filter-btn-active:hover .icon-wrapper > img, .submitted-wrapper .btn.filter-btn-active .icon-wrapper > svg,
      .submitted-wrapper .btn.filter-btn-active .icon-wrapper > img,
      .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper > svg,
      .submitted-wrapper .btn.filter-btn-active:hover .icon-wrapper > img {
        filter: invert(100) brightness(200); }
    @media (max-width: 550px) {
      .auctions-wrapper .btn.filter-btn, .watchlist-wrapper .btn.filter-btn, .submitted-wrapper .btn.filter-btn {
        width: 100%;
        max-width: 100%; } }

@media (max-width: 550px) {
  .submitted-wrapper .watch-info .watch-auction .watch-lots .lot-item .lot-info-bottom .info-btns {
    justify-content: start;
    align-items: start; } }

@media (max-width: 550px) {
  .watchlist-wrapper .watch-lots .lot-item .lot-info .lot-info-bottom {
    justify-content: start;
    align-items: start; } }

@media (max-width: 550px) {
  .watchlist-wrapper .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
    justify-content: start;
    align-items: start; } }

.auctions-controls, .watchlist-controls {
  padding: 10px 0px 10px 0px;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6; }
  .auctions-controls .filters-heading, .watchlist-controls .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center; }
  .auctions-controls .auctions-filters-wrapper, .watchlist-controls .auctions-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 550px) {
      .auctions-controls .auctions-filters-wrapper, .watchlist-controls .auctions-filters-wrapper {
        flex-direction: row; } }
  .auctions-controls .btn, .watchlist-controls .btn {
    margin-top: 10px !important; }

.auction-info, .watch-auction {
  display: flex;
  flex: 2;
  flex-direction: column; }
  .auction-info .auction-info-top, .auction-info .auction-info-middle, .watch-auction .auction-info-top, .watch-auction .auction-info-middle {
    border-bottom: 1px solid #c6c6c6; }
  .auction-info .auction-info-top, .watch-auction .auction-info-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 12px 12px 25px; }
    @media (min-width: 550px) {
      .auction-info .auction-info-top, .watch-auction .auction-info-top {
        flex-direction: row; } }
    @media (max-width: 550px) {
      .auction-info .auction-info-top, .watch-auction .auction-info-top {
        padding: 12px 12px 12px 19px; } }
    .auction-info .auction-info-top .auction-title, .auction-info .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-title, .watch-auction .auction-info-top .auction-live-date {
      margin: 0; }
    .auction-info .auction-info-top .auction-title, .watch-auction .auction-info-top .auction-title {
      color: #000000;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 10px; }
    .auction-info .auction-info-top .auction-live-date, .auction-info .auction-info-top .auction-closed-date, .watch-auction .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-closed-date {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 15px; }
      @media (min-width: 550px) {
        .auction-info .auction-info-top .auction-live-date, .auction-info .auction-info-top .auction-closed-date, .watch-auction .auction-info-top .auction-live-date, .watch-auction .auction-info-top .auction-closed-date {
          line-height: 36px;
          margin-bottom: 0; } }
    .auction-info .auction-info-top .info-btns, .watch-auction .auction-info-top .info-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap; }
      .auction-info .auction-info-top .info-btns > *:not(:first-child), .watch-auction .auction-info-top .info-btns > *:not(:first-child) {
        margin-top: 10px; }
  .auction-info .auction-info-middle, .watch-auction .auction-info-middle {
    display: flex;
    flex-direction: column;
    min-height: 90px; }
    @media (min-width: 550px) {
      .auction-info .auction-info-middle, .watch-auction .auction-info-middle {
        flex-direction: row; } }
    .auction-info .auction-info-middle .auction-type, .watch-auction .auction-info-middle .auction-type {
      width: 100%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ededed; }
      @media (min-width: 550px) {
        .auction-info .auction-info-middle .auction-type, .watch-auction .auction-info-middle .auction-type {
          max-width: 126px;
          border-bottom: none;
          border-right: 1px solid #ededed; } }
      .auction-info .auction-info-middle .auction-type img, .watch-auction .auction-info-middle .auction-type img {
        max-width: 100%;
        max-height: 50px; }
    .auction-info .auction-info-middle .auction-desc, .watch-auction .auction-info-middle .auction-desc {
      flex: 1;
      font-size: 12px;
      color: #5d5d5d;
      line-height: 24px;
      font-family: 'Lato';
      padding: 15px 30px 15px 8px;
      white-space: break-spaces; }
      @media (max-width: 550px) {
        .auction-info .auction-info-middle .auction-desc, .watch-auction .auction-info-middle .auction-desc {
          padding-left: 19px; } }
      .auction-info .auction-info-middle .auction-desc .auction-readMore, .watch-auction .auction-info-middle .auction-desc .auction-readMore {
        color: #e6bd2f;
        font-size: 12px;
        text-decoration: none;
        cursor: pointer; }
    .auction-info .auction-info-middle .auction-logo, .watch-auction .auction-info-middle .auction-logo {
      width: 100%;
      min-height: 75px;
      padding: 7px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #ededed; }
      @media (min-width: 550px) {
        .auction-info .auction-info-middle .auction-logo, .watch-auction .auction-info-middle .auction-logo {
          max-width: 250px;
          border-top: none;
          border-left: 1px solid #ededed; } }
      .auction-info .auction-info-middle .auction-logo img, .watch-auction .auction-info-middle .auction-logo img {
        max-width: 100%;
        max-height: 75px; }
  .auction-info .auction-info-bottom, .watch-auction .auction-info-bottom {
    margin: 20px;
    display: flex;
    flex-wrap: wrap; }
    .auction-info .auction-info-bottom > span, .watch-auction .auction-info-bottom > span {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      padding: 0 10px;
      margin-bottom: 5px;
      border-radius: 5px;
      border: 1px solid #5d5d5d; }
      .auction-info .auction-info-bottom > span:not(:last-of-type), .watch-auction .auction-info-bottom > span:not(:last-of-type) {
        margin-right: 5px; }

.watch-auction .auction-info-top .line1, .watch-auction .auction-info-top .line2 {
  margin-left: 0 !important; }

@media (max-width: 900px) {
  .auctions-wrapper .auctions-list .auction-item {
    display: block; }
    .auctions-wrapper .auctions-list .auction-item .auction-img-container {
      min-width: 250px;
      max-width: 100%;
      border-right: none;
      border-bottom: 1px solid #c6c6c6; } }

.icon-blackened {
  filter: brightness(0); }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.lots-wrapper .top-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .lots-wrapper .top-header .left, .lots-wrapper .top-header .right {
    margin-bottom: 20px; }
  .lots-wrapper .top-header .auction-title {
    color: #030404;
    font-family: Lato;
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 20px 0;
    margin-bottom: 10px; }
    @media (max-width: 550px) {
      .lots-wrapper .top-header .auction-title {
        font-size: 28px; } }
    .lots-wrapper .top-header .auction-title.sub {
      font-size: 28px; }
  .lots-wrapper .top-header .auction-subtitle {
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 300;
    color: #545757;
    font-family: 'Lato'; }
  .lots-wrapper .top-header .auction-live-date, .lots-wrapper .top-header .auction-closed-date {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin: 0 0 20px 0; }
    @media (max-width: 550px) {
      .lots-wrapper .top-header .auction-live-date, .lots-wrapper .top-header .auction-closed-date {
        font-size: 16px; } }
  .lots-wrapper .top-header .auction-desc {
    margin: 0;
    color: #030404;
    font: 400 16px Lato;
    white-space: break-spaces; }
  .lots-wrapper .top-header .auction-logo img {
    max-width: 250px;
    max-height: 125px; }
  .lots-wrapper .top-header .auction-live-date .status {
    display: inline-block;
    padding-left: 32px; }
    @media (max-width: 605px) {
      .lots-wrapper .top-header .auction-live-date .status {
        display: block;
        padding-left: 0;
        margin-top: 4px; } }
    .lots-wrapper .top-header .auction-live-date .status-open {
      color: #fac917; }
    .lots-wrapper .top-header .auction-live-date .status-live {
      color: #1f9512; }

.lots-wrapper .top-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .lots-wrapper .top-btns.upcoming {
    justify-content: space-between; }
  .lots-wrapper .top-btns .left, .lots-wrapper .top-btns .right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 550px) {
      .lots-wrapper .top-btns .left, .lots-wrapper .top-btns .right {
        width: 100%; } }
  .lots-wrapper .top-btns .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    flex-shrink: 0;
    margin: 0 10px 10px 0; }
    .lots-wrapper .top-btns .btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
    .lots-wrapper .top-btns .btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 44px;
      height: 100%;
      font-size: 22px;
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #000000;
      color: #fff; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-green {
        color: #1f9512 !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-success {
        background-color: #1f9512 !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-warning {
        background-color: #fac917 !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-danger {
        color: #dd2d2e !important; }
      .lots-wrapper .top-btns .btn .icon-wrapper.icon-red {
        background-color: #dd2d2e !important; }
    @media (max-width: 550px) {
      .lots-wrapper .top-btns .btn {
        margin-right: 0;
        width: 100%; } }
    .lots-wrapper .top-btns .btn.enter-auction-btn {
      color: #fff;
      background-color: #fac917;
      margin-right: 0; }
  .lots-wrapper .top-btns.results {
    border-bottom: 1px solid #c6c6c6; }

.lots-wrapper .table-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 25px 0;
  padding: 40px 25px;
  background-color: #fff;
  border-radius: 5px 5px 0 0; }
  .lots-wrapper .table-wrapper > div,
  .lots-wrapper .table-wrapper > span {
    font-family: Lato;
    font-size: 16px;
    display: flex;
    padding: 5px; }
    .lots-wrapper .table-wrapper > div > div,
    .lots-wrapper .table-wrapper > span > div {
      width: 20%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .lots-wrapper .table-wrapper > div > div:first-child,
    .lots-wrapper .table-wrapper > span > div:first-child {
      width: 60%;
      min-width: 130px !important;
      padding-right: 15px; }
  .lots-wrapper .table-wrapper .table-title {
    background-color: #666666;
    padding: 15px;
    min-width: 640px;
    justify-content: center;
    font-family: Lato;
    font-size: 14px;
    color: #fff;
    margin: 0 5px; }
  .lots-wrapper .table-wrapper .table-header, .lots-wrapper .table-wrapper .table-footer, .lots-wrapper .table-wrapper .table-row {
    font-weight: bold; }
    .lots-wrapper .table-wrapper .table-header div, .lots-wrapper .table-wrapper .table-footer div, .lots-wrapper .table-wrapper .table-row div {
      padding: 15px;
      font-family: Lato;
      font-size: 14px; }
  .lots-wrapper .table-wrapper .table-header div, .lots-wrapper .table-wrapper .table-footer div {
    margin: 1px;
    border: 1px solid #666;
    background-color: #c6c6c6;
    color: #4d4e4e; }
  .lots-wrapper .table-wrapper .table-row {
    padding-top: 0;
    padding-bottom: 0; }
    .lots-wrapper .table-wrapper .table-row.single-row div {
      min-width: 100px;
      border: 1px solid #c6c6c6; }
    .lots-wrapper .table-wrapper .table-row:not(.single-row):first-of-type div {
      border: 1px solid #c6c6c6;
      border-bottom: none; }
    .lots-wrapper .table-wrapper .table-row:not(.single-row):not(:first-of-type) div, .lots-wrapper .table-wrapper .table-row:not(.single-row):not(:last-of-type) div {
      border-left: 1px solid #c6c6c6;
      border-right: 1px solid #c6c6c6; }
    .lots-wrapper .table-wrapper .table-row:not(.single-row):last-of-type div {
      border: 1px solid #c6c6c6;
      border-top: none; }
    .lots-wrapper .table-wrapper .table-row div {
      margin: 0 1px;
      background-color: #ededed;
      color: #030404; }
  .lots-wrapper .table-wrapper.table-auction-results {
    overflow-x: scroll;
    display: inline-flex; }
    .lots-wrapper .table-wrapper.table-auction-results span.table-row div {
      min-width: 100px; }
    @media (max-width: 700px) {
      .lots-wrapper .table-wrapper.table-auction-results {
        padding-left: 0;
        padding-right: 0; } }
    .lots-wrapper .table-wrapper.table-auction-results div.table-header div,
    .lots-wrapper .table-wrapper.table-auction-results div.table-row div,
    .lots-wrapper .table-wrapper.table-auction-results div.table-footer div {
      min-width: 100px; }

.lots-wrapper .lots-controls {
  padding: 10px 0;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6; }
  .lots-wrapper .lots-controls .filters-heading {
    margin: 0;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    text-align: center; }
  .lots-wrapper .lots-controls .lots-filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 550px) {
      .lots-wrapper .lots-controls .lots-filters-wrapper {
        flex-direction: row; } }
    @media (min-width: 1250px) {
      .lots-wrapper .lots-controls .lots-filters-wrapper {
        justify-content: space-between; } }
  .lots-wrapper .lots-controls .filter-btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding-left: 10px;
    margin-top: 10px;
    position: initial; }
    .lots-wrapper .lots-controls .filter-btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
    .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 44px;
      height: 100%;
      font-size: 22px;
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #000000;
      color: #fff; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-green {
        color: #1f9512 !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-success {
        background-color: #1f9512 !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-warning {
        background-color: #fac917 !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-danger {
        color: #dd2d2e !important; }
      .lots-wrapper .lots-controls .filter-btn .icon-wrapper.icon-red {
        background-color: #dd2d2e !important; }
    .lots-wrapper .lots-controls .filter-btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    @media (max-width: 550px) {
      .lots-wrapper .lots-controls .filter-btn {
        width: 100%; } }
    @media (min-width: 551px) {
      .lots-wrapper .lots-controls .filter-btn:not(:last-of-type) {
        margin-right: 10px; } }
    .lots-wrapper .lots-controls .filter-btn:hover:not(.btn-opened) {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
      .lots-wrapper .lots-controls .filter-btn:hover:not(.btn-opened) .icon-wrapper {
        background-color: #fac917; }
    .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
      transition: 200ms;
      color: #5d5d5d; }
      @media (min-width: 550px) and (max-width: 900px) {
        .lots-wrapper .lots-controls .filter-btn .icon-wrapper {
          margin-left: 10px; } }
    @media (min-width: 550px) {
      .lots-wrapper .lots-controls .filter-btn-sm {
        max-width: 120px; }
      .lots-wrapper .lots-controls .filter-btn-md {
        max-width: 155px; }
      .lots-wrapper .lots-controls .filter-btn-lg {
        max-width: 230px; } }
    .lots-wrapper .lots-controls .filter-btn-active:not(.btn-opened) {
      border-color: #1f9512; }
    .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper,
    .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper {
      background-color: #1f9512;
      border-color: #1f9512; }
      .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper > svg,
      .lots-wrapper .lots-controls .filter-btn-active .icon-wrapper > img,
      .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper > svg,
      .lots-wrapper .lots-controls .filter-btn-active:hover .icon-wrapper > img {
        filter: invert(100) brightness(200); }

.lots-wrapper .catalog-settings {
  display: flex;
  justify-content: space-between;
  margin: 30px 0; }
  .lots-wrapper .catalog-settings > * {
    flex: 1; }
  .lots-wrapper .catalog-settings .catalog-header {
    align-self: flex-end;
    margin: 0;
    font: 400 24px Lato;
    color: #5d5d5d;
    text-transform: uppercase;
    text-align: center; }
  .lots-wrapper .catalog-settings .view-modes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .lots-wrapper .catalog-settings .view-modes .view-mode-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 45px;
      height: 45px;
      background-color: #fff;
      border: 1px solid #464646;
      border-radius: 5px;
      cursor: pointer;
      overflow: hidden; }
      @media (max-width: 900px) {
        .lots-wrapper .catalog-settings .view-modes .view-mode-button {
          display: none; } }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button:not(:last-child) {
        margin-right: 5px; }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button:before {
        content: '';
        width: 100%;
        height: 100%;
        transform: scale(1.1);
        background-size: cover;
        background-position: center; }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button.view-mode-list:before {
        background-image: url("./assets/img/view-list.png"); }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button.view-mode-grid:before {
        background-image: url("./assets/img/view-grid.png"); }
      .lots-wrapper .catalog-settings .view-modes .view-mode-button.active {
        background-color: #1f9512; }
        .lots-wrapper .catalog-settings .view-modes .view-mode-button.active:before {
          filter: brightness(0) invert(1); }

.lots {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 900px) {
    .lots {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .lots .left-side {
    width: 100%; }

.lot-info-wrapper {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 900px) {
    .lot-info-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .lot-info-wrapper .left-side {
    width: 100%; }
  .lot-info-wrapper .calculator {
    margin-top: 20px !important; }

.lots-list, .watch-lots {
  display: flex;
  flex-direction: column;
  position: relative;
  zoom: 80%;
  margin-top: 25px; }
  .lots-list p, .watch-lots p {
    margin: 0; }
  .lots-list.results .lot-item.lot-item-withdrawn .lot-info-middle, .watch-lots.results .lot-item.lot-item-withdrawn .lot-info-middle {
    background-color: #626262; }
  .lots-list .lot-item, .watch-lots .lot-item {
    display: flex;
    position: relative;
    margin-bottom: 25px;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #2e2e2e; }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-top .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-item-top .lot-info .lot-info-bottom {
        justify-content: flex-start;
        align-items: flex-start; } }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-top .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-item-top .lot-info .lot-info-bottom .info-wrap {
        justify-content: flex-start;
        align-items: flex-start; } }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom {
        align-items: flex-start; } }
    @media (max-width: 550px) {
      .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-wrap {
        align-items: flex-start; } }
    .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns {
      width: 192px;
      justify-content: flex-end; }
      .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child {
        margin-bottom: 5px;
        margin-right: 0 !important; }
        @media (max-width: 550px) {
          .lots-list .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child, .watch-lots .lot-item .lot-item-bottom .lot-info .lot-info-bottom .info-btns a:first-child {
            margin-bottom: 0; } }
    .lots-list .lot-item.dump, .watch-lots .lot-item.dump {
      border: 0;
      box-shadow: none;
      background-color: transparent; }
    .lots-list .lot-item.lot-item-withdrawn, .watch-lots .lot-item.lot-item-withdrawn {
      background-color: #626262; }
      .lots-list .lot-item.lot-item-withdrawn .lot-info, .watch-lots .lot-item.lot-item-withdrawn .lot-info {
        background-color: #cccccc; }
        .lots-list .lot-item.lot-item-withdrawn .lot-info .lot-info-middle, .watch-lots .lot-item.lot-item-withdrawn .lot-info .lot-info-middle {
          min-height: auto;
          border-bottom: 0; }
          .lots-list .lot-item.lot-item-withdrawn .lot-info .lot-info-middle .lot-title, .watch-lots .lot-item.lot-item-withdrawn .lot-info .lot-info-middle .lot-title {
            text-decoration: line-through;
            margin: 0; }
    .lots-list .lot-item.existing, .watch-lots .lot-item.existing {
      display: flex;
      flex: 1;
      flex-direction: column; }
      .lots-list .lot-item.existing .lot-item-top, .watch-lots .lot-item.existing .lot-item-top {
        flex: 1;
        display: flex;
        position: relative; }
      .lots-list .lot-item.existing .lot-item-bottom, .watch-lots .lot-item.existing .lot-item-bottom {
        flex: 1;
        border-top: 1px solid #c6c6c6; }
    .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: 240px; }
      @media (max-width: 900px) {
        .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
          width: 100%; } }
    .lots-list .lot-item .lot-info-top, .watch-lots .lot-item .lot-info-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 9px 16px;
      color: #fff;
      background-color: #2e2e2e;
      font: 100 16px Lato; }
      .lots-list .lot-item .lot-info-top .lot-number .my-listing, .watch-lots .lot-item .lot-info-top .lot-number .my-listing {
        padding-left: 5px; }
        .lots-list .lot-item .lot-info-top .lot-number .my-listing .icon-wrapper, .watch-lots .lot-item .lot-info-top .lot-number .my-listing .icon-wrapper {
          position: relative;
          top: -2px;
          padding: 0 5px; }
      .lots-list .lot-item .lot-info-top .lot-location, .watch-lots .lot-item .lot-info-top .lot-location {
        text-transform: uppercase;
        text-align: right; }
    .lots-list .lot-item .lot-info, .watch-lots .lot-item .lot-info {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-family: Lato; }
      .lots-list .lot-item .lot-info .lot-info-middle, .watch-lots .lot-item .lot-info .lot-info-middle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0;
        min-height: 100px;
        padding: 5px 10px 8px 20px;
        border-bottom: 1px solid #c6c6c6; }
        @media (min-width: 550px) {
          .lots-list .lot-item .lot-info .lot-info-middle, .watch-lots .lot-item .lot-info .lot-info-middle {
            flex-direction: row; } }
        .lots-list .lot-item .lot-info .lot-info-middle .left, .watch-lots .lot-item .lot-info .lot-info-middle .left {
          padding-right: 16px;
          max-width: fit-content; }
          .lots-list .lot-item .lot-info .lot-info-middle .left .lot-title, .watch-lots .lot-item .lot-info .lot-info-middle .left .lot-title {
            margin-top: 0; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-title-wrapper, .watch-lots .lot-item .lot-info .lot-info-middle .lot-title-wrapper {
          display: flex;
          justify-content: space-between;
          min-width: 275px;
          width: 100%;
          margin-bottom: 16px; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-sub-title, .watch-lots .lot-item .lot-info .lot-info-middle .lot-sub-title {
          min-width: 125px;
          color: #2e2e2e;
          font-size: 18px;
          margin: 8px 0 8px; }
        .lots-list .lot-item .lot-info .lot-info-middle .curfew, .watch-lots .lot-item .lot-info .lot-info-middle .curfew {
          color: #e6bd2f; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-title, .watch-lots .lot-item .lot-info .lot-info-middle .lot-title {
          color: #2e2e2e;
          font-size: 18px;
          min-height: 0px;
          margin: 8px 0 12px !important; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state-container, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state-container {
          margin-bottom: 24px; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state {
          display: block;
          font-family: Lato;
          font-size: 17px;
          font-weight: 400;
          color: #dd2d2e;
          margin-bottom: 25px; }
          .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.lot-closed-success, .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.sold, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.lot-closed-success, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.sold {
            color: #1f9512; }
          .lots-list .lot-item .lot-info .lot-info-middle .lot-closed-state.passed, .watch-lots .lot-item .lot-info .lot-info-middle .lot-closed-state.passed {
            color: #f9891a; }
        .lots-list .lot-item .lot-info .lot-info-middle .lot-comments, .watch-lots .lot-item .lot-info .lot-info-middle .lot-comments {
          word-break: break-word;
          margin-top: 12px;
          color: #696969;
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          font-size: 12px;
          cursor: pointer; }
          .lots-list .lot-item .lot-info .lot-info-middle .lot-comments .lot-readMore, .watch-lots .lot-item .lot-info .lot-info-middle .lot-comments .lot-readMore {
            color: #e6bd2f;
            font-size: 12px;
            text-decoration: none;
            cursor: pointer; }
        .lots-list .lot-item .lot-info .lot-info-middle .info-btns, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns {
          margin-bottom: 8px; }
          .lots-list .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child), .watch-lots .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child) {
            margin-bottom: 8px; }
          .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 231px;
            height: 44px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #000000;
            text-align: left;
            color: #030404;
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            transition: box-shadow 300ms;
            padding-left: 20px;
            text-decoration: none;
            overflow: hidden;
            user-select: none;
            width: 184px;
            height: 35px; }
            .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn:hover, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn:hover {
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
            .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0 0 44px;
              height: 100%;
              font-size: 22px;
              border-radius: 5px 0 0 5px;
              border-left: 1px solid #000000;
              color: #fff; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-green, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-green {
                color: #1f9512 !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-success, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-success {
                background-color: #1f9512 !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-warning, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-warning {
                background-color: #fac917 !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-danger, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-danger {
                color: #dd2d2e !important; }
              .lots-list .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-red, .watch-lots .lot-item .lot-info .lot-info-middle .info-btns .btn .icon-wrapper.icon-red {
                background-color: #dd2d2e !important; }
      .lots-list .lot-item .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-info .lot-info-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 8px 16px; }
        @media (min-width: 550px) {
          .lots-list .lot-item .lot-info .lot-info-bottom, .watch-lots .lot-item .lot-info .lot-info-bottom {
            flex-direction: row; } }
        .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
          display: flex;
          justify-content: center;
          flex-wrap: wrap; }
          @media (min-width: 550px) {
            .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap {
              justify-content: flex-start; } }
          .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span {
            display: inline-block;
            line-height: 35px;
            padding: 0 7px;
            border-radius: 5px;
            border: 1px solid #5d5d5d;
            color: #5d5d5d;
            font-family: Lato;
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 5px; }
            @media (min-width: 1250px) {
              .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span {
                margin-bottom: 0;
                justify-content: flex-start; } }
            .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap span:not(:last-child), .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap span:not(:last-child) {
              margin-right: 5px; }
          .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap .optiweigh-logo-wrapper, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap .optiweigh-logo-wrapper {
            display: flex; }
            .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap .optiweigh-logo-wrapper img, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap .optiweigh-logo-wrapper img {
              align-self: center;
              max-height: 25px;
              margin-bottom: 10px; }
            @media (max-width: 1035px) {
              .lots-list .lot-item .lot-info .lot-info-bottom .info-wrap .optiweigh-logo-wrapper, .watch-lots .lot-item .lot-info .lot-info-bottom .info-wrap .optiweigh-logo-wrapper {
                margin-top: 10px;
                margin-bottom: 10px; } }
        .lots-list .lot-item .lot-info .lot-info-bottom .company-logo-wrapper img, .watch-lots .lot-item .lot-info .lot-info-bottom .company-logo-wrapper img {
          max-height: 40px; }

@media (min-width: 900px) {
  .lots-list.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    .lots-list.grid .lot-item {
      display: flex;
      flex-direction: column;
      width: 32%;
      height: 783px; }
      .lots-list.grid .lot-item .lot-img-container {
        width: 100%;
        height: 250px; }
      .lots-list.grid .lot-item .lot-info .lot-info-middle {
        padding: 9px 16px;
        flex-direction: column; }
        .lots-list.grid .lot-item .lot-info .lot-info-middle .left {
          padding: 0; }
          .lots-list.grid .lot-item .lot-info .lot-info-middle .left .lot-title {
            height: 80px;
            text-align: center;
            margin-top: 0; }
        .lots-list.grid .lot-item .lot-info .lot-info-middle .info-btns {
          display: flex;
          flex-direction: row;
          margin: 10px 0; }
          .lots-list.grid .lot-item .lot-info .lot-info-middle .info-btns > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 5px; }
      .lots-list.grid .lot-item .lot-info .lot-info-bottom {
        flex-direction: column;
        padding: 0; }
        .lots-list.grid .lot-item .lot-info .lot-info-bottom .info-wrap {
          width: 100%;
          justify-content: center;
          padding: 12px 16px 8px 16px;
          border-bottom: 1px solid #c6c6c6; }
          .lots-list.grid .lot-item .lot-info .lot-info-bottom .info-wrap span {
            margin-bottom: 4px; }
        .lots-list.grid .lot-item .lot-info .lot-info-bottom .company-logo-wrapper {
          height: 64px;
          padding: 12px 0; } }

@media (max-width: 1250px) and (min-width: 900px) {
  .lots-list.grid .lot-item {
    width: 49%; }
  .lots-list.grid.dump {
    display: none; } }

@media (max-width: 1100px) and (min-width: 900px) {
  .lots-list.grid .lot-item {
    height: 681px;
    width: 49%; } }

@media (max-width: 900px) {
  .lots-wrapper .catalog-settings {
    flex-direction: column; }
    .lots-wrapper .catalog-settings .catalog-header {
      margin-top: 10px;
      align-self: center; }
  .lots-list .lot-item, .watch-lots .lot-item {
    display: block; }
    .lots-list .lot-item.existing .lot-item-top, .watch-lots .lot-item.existing .lot-item-top {
      display: block; }
    .lots-list .lot-item .lot-img-container, .watch-lots .lot-item .lot-img-container {
      height: 270px; }
      .lots-list .lot-item .lot-img-container-withdrawn, .watch-lots .lot-item .lot-img-container-withdrawn {
        height: 100px; } }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.lot-wrapper {
  position: relative; }
  .lot-wrapper .hidden {
    display: none; }
  @media (max-width: 900px) {
    .lot-wrapper .lot-info .title-block {
      justify-content: center;
      align-items: center; } }
  .lot-wrapper .top-btns {
    display: flex; }
    .lot-wrapper .top-btns.results {
      flex-wrap: wrap; }
      .lot-wrapper .top-btns.results .back-btn {
        width: 228px; }
        @media (max-width: 550px) {
          .lot-wrapper .top-btns.results .back-btn {
            width: 100%; } }
      .lot-wrapper .top-btns.results .watching-btn {
        width: 228px;
        height: 44px;
        margin-bottom: 10px; }
        @media (max-width: 550px) {
          .lot-wrapper .top-btns.results .watching-btn {
            width: 100%;
            margin-right: 0; } }
      .lot-wrapper .top-btns.results.results-flex {
        flex-wrap: nowrap; }
        .lot-wrapper .top-btns.results.results-flex .back-btn {
          min-width: 180px;
          width: auto;
          flex-grow: 1; }
          @media (max-width: 550px) {
            .lot-wrapper .top-btns.results.results-flex .back-btn {
              width: 100%; } }
        .lot-wrapper .top-btns.results.results-flex .watching-btn {
          min-width: 175px;
          max-width: 180px;
          width: auto;
          flex-grow: 1; }
          @media (max-width: 900px) {
            .lot-wrapper .top-btns.results.results-flex .watching-btn {
              min-width: 220px;
              max-width: 800px; } }
          @media (max-width: 550px) {
            .lot-wrapper .top-btns.results.results-flex .watching-btn {
              width: 100%;
              max-width: 1000px; } }
        @media (max-width: 900px) {
          .lot-wrapper .top-btns.results.results-flex {
            flex-wrap: wrap; } }
  .lot-wrapper .btn {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 231px;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: left;
    color: #030404;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: box-shadow 300ms;
    padding-left: 20px;
    text-decoration: none;
    overflow: hidden;
    user-select: none;
    width: 228px;
    margin: 0 10px 10px 0; }
    .lot-wrapper .btn:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
    .lot-wrapper .btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 44px;
      height: 100%;
      font-size: 22px;
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #000000;
      color: #fff; }
      .lot-wrapper .btn .icon-wrapper.icon-green {
        color: #1f9512 !important; }
      .lot-wrapper .btn .icon-wrapper.icon-success {
        background-color: #1f9512 !important; }
      .lot-wrapper .btn .icon-wrapper.icon-warning {
        background-color: #fac917 !important; }
      .lot-wrapper .btn .icon-wrapper.icon-danger {
        color: #dd2d2e !important; }
      .lot-wrapper .btn .icon-wrapper.icon-red {
        background-color: #dd2d2e !important; }
    .lot-wrapper .btn.btn-download-file {
      width: 271px; }
      @media (max-width: 1280px) {
        .lot-wrapper .btn.btn-download-file {
          width: 228px;
          margin: 0 10px 10px 0; } }
      @media (max-width: 900px) {
        .lot-wrapper .btn.btn-download-file {
          width: 100%;
          margin: 0 10px 10px 0; } }
      @media (max-width: 550px) {
        .lot-wrapper .btn.btn-download-file {
          width: 100%;
          margin: 0 0 10px 0; } }
    @media (max-width: 550px) {
      .lot-wrapper .btn {
        width: 100%;
        margin: 0 0 10px 0; } }
    .lot-wrapper .btn.btn-flex {
      width: auto;
      flex-grow: 1; }
      @media (max-width: 900px) {
        .lot-wrapper .btn.btn-flex {
          min-width: 220px;
          width: 228px; } }
      @media (max-width: 550px) {
        .lot-wrapper .btn.btn-flex {
          width: 100%; } }
      .lot-wrapper .btn.btn-flex .btn-title {
        display: inline-block; }
  .lot-wrapper .lot-number {
    color: #5d5d5d;
    font-family: Lato;
    font-size: 20px;
    font-weight: 400; }
  .lot-wrapper .lot-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; }
    .lot-wrapper .lot-menu .auction-title, .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
      margin: 0; }
    .lot-wrapper .lot-menu .auction-title {
      color: #000000;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 15px; }
    .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
      color: #5d5d5d;
      font-family: Lato;
      font-size: 24px;
      font-weight: 400;
      margin: 0; }
      @media (max-width: 550px) {
        .lot-wrapper .lot-menu .auction-live-date, .lot-wrapper .lot-menu .auction-closed-date {
          font-size: 16px; } }
    .lot-wrapper .lot-menu .right {
      display: flex;
      order: 1;
      border-left: 0; }
    .lot-wrapper .lot-menu .left .left {
      order: 2; }
    .lot-wrapper .lot-menu .left .back-img:before {
      transform: rotate(360deg) !important; }
  .lot-wrapper .lot-number {
    margin: 0 0 20px 0; }
  .lot-wrapper .pdf-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .lot-wrapper .pdf-buttons .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 31px;
      font-family: 'Myriad Pro';
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.26);
      transition: background-color 0.2s;
      margin: 0;
      user-select: none;
      text-decoration: none;
      margin-bottom: 20px;
      margin-right: 15px;
      color: #333333;
      background-color: #cccccc; }
      .lot-wrapper .pdf-buttons .btn:hover {
        background-color: #aeaeae; }
      .lot-wrapper .pdf-buttons .btn.hidden {
        display: none; }
  .lot-wrapper .lot-info {
    position: relative;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: white;
    font-family: "Lato"; }
    .lot-wrapper .lot-info .lot-info-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 -50px;
      height: 59px;
      border-radius: 5px 5px 0 0;
      background-color: #2e2e2e;
      color: white;
      font-size: 32px;
      padding: 0 17px; }
      @media (max-width: 900px) {
        .lot-wrapper .lot-info .lot-info-top {
          font-size: 24px;
          margin: 0 -10px; } }
      @media (max-width: 550px) {
        .lot-wrapper .lot-info .lot-info-top {
          font-size: 17px; } }
      .lot-wrapper .lot-info .lot-info-top p {
        margin: 0; }
    .lot-wrapper .lot-info .lot-title-wrapper {
      display: flex;
      justify-content: space-between; }
      .lot-wrapper .lot-info .lot-title-wrapper .company-logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media (max-width: 550px) {
          .lot-wrapper .lot-info .lot-title-wrapper .company-logo-wrapper {
            width: 150px;
            height: 100px;
            margin-left: 5px; } }
        .lot-wrapper .lot-info .lot-title-wrapper .company-logo-wrapper img {
          max-height: 50px;
          object-fit: contain; }
    .lot-wrapper .lot-info .lot-title-weights {
      display: flex;
      width: 100%;
      font-size: 20px;
      margin-bottom: 10px; }
      .lot-wrapper .lot-info .lot-title-weights .title-weight {
        font-weight: 600;
        color: #5d5d5d;
        font-family: 'Lato';
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        flex: 1;
        border: 1px solid black;
        border-radius: 5px 0 0 0;
        margin: 0; }
        .lot-wrapper .lot-info .lot-title-weights .title-weight.curfew {
          color: #DBB52C;
          background-color: #5d5d5d;
          border-radius: 0 5px 0 0; }
    .lot-wrapper .lot-info .details {
      margin-top: 20px; }
    .lot-wrapper .lot-info .lot-title {
      display: block;
      color: #2e2e2e;
      font-size: 36px;
      margin: 36px 0 0 0; }
      @media (max-width: 900px) {
        .lot-wrapper .lot-info .lot-title {
          font-size: 20px; } }
      @media (max-width: 600px) {
        .lot-wrapper .lot-info .lot-title {
          font-size: 18px; } }
      @media (max-width: 360px) {
        .lot-wrapper .lot-info .lot-title {
          max-width: 135px;
          margin-left: 25px;
          margin-right: 5px; } }
  .lot-wrapper .lot-closed-state {
    display: block;
    font-size: 36px;
    margin-top: 8px;
    min-width: 280px;
    font-weight: 100;
    color: #dd2d2e;
    word-break: break-word; }
    @media (max-width: 900px) {
      .lot-wrapper .lot-closed-state {
        font-size: 26px; } }
    @media (max-width: 600px) {
      .lot-wrapper .lot-closed-state {
        min-width: 180px; } }
    @media (max-width: 600px) {
      .lot-wrapper .lot-closed-state {
        font-size: 22px; } }
    @media (max-width: 420px) {
      .lot-wrapper .lot-closed-state {
        min-width: 160px;
        font-size: 20px; } }
    @media (max-width: 350px) {
      .lot-wrapper .lot-closed-state {
        margin-left: 25px; } }
    .lot-wrapper .lot-closed-state.sold, .lot-wrapper .lot-closed-state.soldAfterAuction {
      color: #1f9512; }
    .lot-wrapper .lot-closed-state.passed {
      color: #f9891a; }

.lot-purchaser-location {
  display: block;
  font-size: 20px;
  color: #1f9512; }
  @media (max-width: 600px) {
    .lot-purchaser-location {
      min-width: 180px; } }
  @media (max-width: 420px) {
    .lot-purchaser-location {
      min-width: 160px;
      font-size: 12px; } }
  @media (max-width: 350px) {
    .lot-purchaser-location {
      margin-left: 25px; } }

@media (max-width: 900px) {
  .lot-wrapper .lot-info {
    border-left: 0;
    border-right: 0;
    padding: 0 10px; } }

@media (max-width: 633px) {
  .lot-wrapper .lot-menu > .left {
    margin-bottom: 20px; } }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.back-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 231px;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: left;
  color: #030404;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: box-shadow 300ms;
  padding-left: 20px;
  text-decoration: none;
  overflow: hidden;
  user-select: none;
  padding-left: 0;
  padding-right: 20px;
  padding-right: 10px;
  flex-shrink: 0;
  margin: 0 10px 10px 0;
  justify-content: flex-start; }
  .back-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
  .back-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff; }
    .back-btn .icon-wrapper.icon-green {
      color: #1f9512 !important; }
    .back-btn .icon-wrapper.icon-success {
      background-color: #1f9512 !important; }
    .back-btn .icon-wrapper.icon-warning {
      background-color: #fac917 !important; }
    .back-btn .icon-wrapper.icon-danger {
      color: #dd2d2e !important; }
    .back-btn .icon-wrapper.icon-red {
      background-color: #dd2d2e !important; }
  .back-btn .icon-wrapper {
    border-left: 0;
    border-right: 1px solid #000000;
    border-radius: 0 5px 5px 0; }
  @media (max-width: 550px) {
    .back-btn {
      margin-right: 0;
      width: 100%; } }
  .back-btn .icon-wrapper {
    margin-right: 10px; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.media-carousel {
  position: relative;
  width: 100%;
  white-space: nowrap;
  margin: 5px 0; }
  .media-carousel .nav-prev, .media-carousel .nav-next {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 25% !important;
    height: 50%;
    display: flex;
    align-items: center;
    user-select: none;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    cursor: pointer; }
    .media-carousel .nav-prev.active, .media-carousel .nav-next.active {
      opacity: 1;
      pointer-events: all;
      z-index: 50; }
    .media-carousel .nav-prev.active:hover, .media-carousel .nav-next.active:hover {
      opacity: 1 !important; }
    .media-carousel .nav-prev .icon-wrapper, .media-carousel .nav-next .icon-wrapper {
      font-size: 25px;
      color: #fff; }
  .media-carousel .nav-prev {
    left: 10px;
    z-index: 9; }
  .media-carousel .nav-next {
    top: 0;
    right: 10px;
    justify-content: flex-end; }
  .media-carousel .media-wrap {
    margin: 0;
    overflow: hidden;
    user-select: none; }
    .media-carousel .media-wrap .media-area {
      position: relative;
      transition: all 0.5s; }
      .media-carousel .media-wrap .media-area label {
        cursor: pointer; }
      .media-carousel .media-wrap .media-area .no-image .file-input {
        display: none; }
      .media-carousel .media-wrap .media-area .carousel-item {
        cursor: pointer;
        width: 320px;
        height: 213px;
        max-width: 100%;
        margin-right: 3px;
        display: inline-block;
        position: relative;
        overflow: hidden; }
        @media (max-width: 900px) {
          .media-carousel .media-wrap .media-area .carousel-item {
            width: 240px;
            height: 180px; } }
        .media-carousel .media-wrap .media-area .carousel-item.with-title {
          height: 235px; }
        .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper {
          height: 100%;
          position: relative; }
          .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper .carousel-img {
            width: unset !important;
            height: unset !important;
            object-fit: contain !important;
            max-width: 100%;
            max-height: 100%; }
          .media-carousel .media-wrap .media-area .carousel-item .video-image-wrapper svg {
            position: absolute;
            top: calc(50% - 75px / 2);
            left: calc(50% - 75px / 2);
            height: 75px;
            width: 75px;
            color: #2e2e2e;
            z-index: 2; }
        .media-carousel .media-wrap .media-area .carousel-item .remove {
          position: absolute;
          top: 15px;
          left: 15px;
          z-index: 2; }
        .media-carousel .media-wrap .media-area .carousel-item .set-main {
          padding: 3px 5px;
          background: #808080;
          border-radius: 5px;
          font: 400 16px "Lato";
          color: #fff;
          position: absolute;
          bottom: 15px;
          right: 15px;
          z-index: 2;
          width: 42%; }
          .media-carousel .media-wrap .media-area .carousel-item .set-main .inner-line {
            display: flex;
            align-items: center; }
            .media-carousel .media-wrap .media-area .carousel-item .set-main .inner-line .set-main-text {
              margin: 0 0 0 5px; }
        .media-carousel .media-wrap .media-area .carousel-item .carousel-img, .media-carousel .media-wrap .media-area .carousel-item .carousel-video {
          display: block; }
        .media-carousel .media-wrap .media-area .carousel-item .date {
          padding: 3px 5px;
          background: #808080;
          border-radius: 5px;
          position: absolute;
          z-index: 2;
          bottom: 15px;
          left: 15px;
          display: flex;
          justify-content: center;
          font: 400 16px "Lato";
          color: #fff; }
        .media-carousel .media-wrap .media-area .carousel-item .carousel-img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          z-index: 1;
          /* max-width: 100%;
          max-height: 100%; */ }
        .media-carousel .media-wrap .media-area .carousel-item .video-title {
          display: flex;
          padding: 5px;
          justify-content: center;
          text-align: center;
          white-space: normal; }
        .media-carousel .media-wrap .media-area .carousel-item .carousel-video {
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: initial; }
          .media-carousel .media-wrap .media-area .carousel-item .carousel-video.with-title {
            height: 80%; }
        .media-carousel .media-wrap .media-area .carousel-item .video-prewiev {
          object-fit: contain; }
        .media-carousel .media-wrap .media-area .carousel-item .spinner {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5; }
          .media-carousel .media-wrap .media-area .carousel-item .spinner.hidden {
            display: none; }
  .media-carousel.preview {
    height: 100%;
    margin: 0; }
    .media-carousel.preview .media-wrap {
      height: 100%;
      margin: 0; }
      .media-carousel.preview .media-wrap .media-area {
        height: 100%; }
        .media-carousel.preview .media-wrap .media-area .carousel-item {
          width: 100%;
          height: 100%;
          margin-right: 0px; }
          @media (max-width: 900px) {
            .media-carousel.preview .media-wrap .media-area .carousel-item {
              width: 100% !important; } }
          .media-carousel.preview .media-wrap .media-area .carousel-item .carousel-img {
            width: 100%;
            height: 100%;
            object-fit: fill !important;
            /* max-width: 100%;
            max-height: 100%; */ }
    .media-carousel.preview .nav-prev {
      background: transparent;
      left: 5px; }
    .media-carousel.preview .nav-next {
      background: transparent;
      right: 5px; }

.carousel-wrap {
  width: 100%;
  height: 100%; }

.dots-wrap {
  padding-bottom: 12px;
  text-align: center; }
  .dots-wrap .dot {
    width: 10px;
    height: 10px;
    margin-right: 9px;
    background: #2e2e2e;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer; }
    .dots-wrap .dot.active {
      background: #8e8e8e; }

canvas {
  width: 320px;
  height: 190px;
  position: absolute;
  top: 20px; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.details, .lot-documents {
  position: relative; }
  .details .row, .lot-documents .row {
    margin-bottom: 5px;
    border-radius: 2px; }
    .details .row .row-title-wrap, .lot-documents .row .row-title-wrap {
      margin: 0;
      background-color: #666666;
      border-bottom: 1px solid #c6c6c6; }
      .details .row .row-title-wrap .row-title, .lot-documents .row .row-title-wrap .row-title {
        padding: 10px 10px;
        margin: 0;
        font: 700 16px "Lato";
        color: white; }
    .details .row .documents-container, .lot-documents .row .documents-container {
      margin-top: 5px;
      padding: 20px 0;
      background-color: #ededed; }
    .details .row .fields-container, .lot-documents .row .fields-container {
      margin-top: 5px;
      display: flex;
      justify-content: space-between; }
      @media (max-width: 900px) {
        .details .row .fields-container, .lot-documents .row .fields-container {
          flex-direction: column; } }
    .details .row .left-column, .details .row .right-column, .lot-documents .row .left-column, .lot-documents .row .right-column {
      border: 1px solid #c6c6c6;
      background-color: #ededed;
      width: 50%; }
      @media (max-width: 900px) {
        .details .row .left-column, .details .row .right-column, .lot-documents .row .left-column, .lot-documents .row .right-column {
          width: 100%;
          border-radius: 0 !important; } }
    .details .row .left-column, .lot-documents .row .left-column {
      margin-right: 5px; }
      @media (max-width: 900px) {
        .details .row .left-column, .lot-documents .row .left-column {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: #c6c6c6; } }
    @media (max-width: 900px) {
      .details .row .right-column, .lot-documents .row .right-column {
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: #c6c6c6; } }
    .details .row .one-column, .lot-documents .row .one-column {
      border: 1px solid #c6c6c6;
      background-color: #ededed;
      width: 100%; }
      .details .row .one-column .option .option-line .option-value, .lot-documents .row .one-column .option .option-line .option-value {
        flex: 5; }
    .details .row .file-wrap, .lot-documents .row .file-wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0 10px; }
      @media (max-width: 550px) {
        .details .row .file-wrap, .lot-documents .row .file-wrap {
          flex-direction: column; } }
      .details .row .file-wrap svg, .lot-documents .row .file-wrap svg {
        margin-left: 5px; }
      .details .row .file-wrap .description, .lot-documents .row .file-wrap .description {
        flex: 1 1; }
      .details .row .file-wrap .file-name, .lot-documents .row .file-wrap .file-name {
        flex: 1 1;
        font-weight: bold;
        margin: 0; }
      .details .row .file-wrap .view-link, .details .row .file-wrap .download-link, .lot-documents .row .file-wrap .view-link, .lot-documents .row .file-wrap .download-link {
        flex: 1 1;
        color: #030404;
        text-decoration: none; }
    .details .row .repeat-option .repeat-title-wrap, .lot-documents .row .repeat-option .repeat-title-wrap {
      margin: 0;
      background-color: white;
      border-width: 1px 0;
      border-style: solid;
      border-color: #c6c6c6; }
      .details .row .repeat-option .repeat-title-wrap .repeat-title, .lot-documents .row .repeat-option .repeat-title-wrap .repeat-title {
        padding: 10px 10px;
        margin: 0;
        color: #5d5d5d;
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        font-size: 16px;
        font-weight: 700; }
    .details .row .repeat-option .repeat-line, .lot-documents .row .repeat-option .repeat-line {
      margin-left: 10px; }
    .details .row .option .option-line, .lot-documents .row .option .option-line {
      display: flex;
      align-items: center; }
      .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px; }
        @media (max-width: 900px) {
          .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
            font-size: 12px;
            padding-top: 5px;
            padding-bottom: 5px; } }
        @media (max-width: 550px) {
          .details .row .option .option-line.text-info, .lot-documents .row .option .option-line.text-info {
            font-size: 9px; } }
      .details .row .option .option-line .option-title, .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-title, .lot-documents .row .option .option-line .option-value {
        word-break: break-word;
        margin: 0;
        padding: 5px 10px;
        color: #030404; }
        .details .row .option .option-line .option-title.empty, .details .row .option .option-line .option-value.empty, .lot-documents .row .option .option-line .option-title.empty, .lot-documents .row .option .option-line .option-value.empty {
          color: #7e7e7e; }
      .details .row .option .option-line .option-title, .lot-documents .row .option .option-line .option-title {
        flex: 1;
        min-width: 100px;
        font: 400 16px "Lato"; }
      .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-value {
        flex: 2; }
        @media (max-width: 550px) {
          .details .row .option .option-line .option-value, .lot-documents .row .option .option-line .option-value {
            flex: 1; } }
        .details .row .option .option-line .option-value .paragraph, .lot-documents .row .option .option-line .option-value .paragraph {
          display: block; }
        .details .row .option .option-line .option-value a, .lot-documents .row .option .option-line .option-value a {
          text-decoration: none; }
        .details .row .option .option-line .option-value .signature-img, .lot-documents .row .option .option-line .option-value .signature-img {
          height: 100px; }
    .details .row .option .signature-icon, .lot-documents .row .option .signature-icon {
      width: 180px; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.app-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  user-select: none;
  max-height: 100%; }
  .app-modal-overlay .app-modal {
    min-width: 300px;
    max-width: 50%;
    min-height: 190px;
    max-height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 25px;
    position: absolute;
    top: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    background-color: white; }
    @media (max-height: 700px) {
      .app-modal-overlay .app-modal {
        max-height: unset; } }
    .app-modal-overlay .app-modal .title, .app-modal-overlay .app-modal .message {
      margin: 0;
      padding: 10px 0; }
    .app-modal-overlay .app-modal .title {
      text-align: center;
      font: 700 25px 'Lato'; }
    .app-modal-overlay .app-modal .message {
      text-align: center;
      font: 400 18px Lato; }
    .app-modal-overlay .app-modal .buttons {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px 0; }
      .app-modal-overlay .app-modal .buttons .modal-button {
        min-height: 2em;
        min-width: 10em;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #5d5d5d;
        color: white;
        font: 400 20px 'Myriad Pro';
        cursor: pointer;
        transition: all 0.3s; }
        .app-modal-overlay .app-modal .buttons .modal-button:hover {
          box-shadow: 2px 2px 2px #888888; }
    @media (max-height: 460px) {
      .app-modal-overlay .app-modal {
        padding-bottom: 0; } }
  .app-modal-overlay.upload-csv .app-modal {
    max-width: 650px;
    min-height: 600px;
    padding: 0;
    display: block; }
    .app-modal-overlay.upload-csv .app-modal .title {
      width: 100%;
      padding-left: 20px;
      background: #5d5d5d;
      text-align: left;
      color: white;
      font: 400 20px "Lato"; }
    .app-modal-overlay.upload-csv .app-modal .buttons {
      width: 100%;
      padding: 20px;
      display: inline-block; }
      .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 231px;
        height: 44px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #000000;
        text-align: left;
        color: #030404;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        transition: box-shadow 300ms;
        padding-left: 20px;
        text-decoration: none;
        overflow: hidden;
        user-select: none;
        min-width: 9em;
        padding-left: 10px;
        justify-content: left;
        border: 1px solid #c6c6c6;
        background-color: #ededed;
        color: #666666; }
        .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
        .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 44px;
          height: 100%;
          font-size: 22px;
          border-radius: 5px 0 0 5px;
          border-left: 1px solid #000000;
          color: #fff; }
          .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-green {
            color: #1f9512 !important; }
          .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-success {
            background-color: #1f9512 !important; }
          .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-warning {
            background-color: #fac917 !important; }
          .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-danger {
            color: #dd2d2e !important; }
          .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper.icon-red {
            background-color: #dd2d2e !important; }
        .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom:first-of-type {
          float: left; }
        .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom:last-of-type {
          float: right; }
        .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom .icon-wrapper {
          width: 44px;
          position: absolute;
          top: 0;
          right: 0;
          border-color: #c6c6c6; }
        @media (max-width: 550px) {
          .app-modal-overlay.upload-csv .app-modal .buttons .modal-button.custom {
            float: none !important; } }
    .app-modal-overlay.upload-csv .app-modal p.message {
      padding: 0 25px;
      text-align: left; }
    .app-modal-overlay.upload-csv .app-modal .custom-message {
      padding: 15px 25px; }
      .app-modal-overlay.upload-csv .app-modal .custom-message .message {
        padding: 0;
        margin: 0 0 20px; }
      .app-modal-overlay.upload-csv .app-modal .custom-message .form-row .row-title {
        width: 160px; }
    .app-modal-overlay.upload-csv .app-modal .fg-dropdown__menu {
      height: 103px;
      min-height: 103px; }
      .app-modal-overlay.upload-csv .app-modal .fg-dropdown__menu .fg-dropdown__menu-list {
        height: 100px;
        min-height: 100px; }
  .app-modal-overlay .registerInterest-success-modal {
    margin-bottom: 815px; }

.ReactModal__Overlay {
  z-index: 50; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.watching-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 231px;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000000;
  text-align: left;
  color: #030404;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: box-shadow 300ms;
  padding-left: 20px;
  text-decoration: none;
  overflow: hidden;
  user-select: none;
  background-color: #fff;
  color: #030404; }
  .watching-btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
  .watching-btn .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 44px;
    height: 100%;
    font-size: 22px;
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #000000;
    color: #fff; }
    .watching-btn .icon-wrapper.icon-green {
      color: #1f9512 !important; }
    .watching-btn .icon-wrapper.icon-success {
      background-color: #1f9512 !important; }
    .watching-btn .icon-wrapper.icon-warning {
      background-color: #fac917 !important; }
    .watching-btn .icon-wrapper.icon-danger {
      color: #dd2d2e !important; }
    .watching-btn .icon-wrapper.icon-red {
      background-color: #dd2d2e !important; }
  .watching-btn.list {
    width: 184px;
    height: 35px; }
  .watching-btn .icon-wrapper {
    background-color: #F9891A;
    transition: 400ms;
    color: #fff; }
  .watching-btn.active {
    background-color: #F9891A;
    color: #fff; }
    .watching-btn.active .icon-wrapper {
      background-color: #fff;
      transition: 400ms;
      color: #F9891A; }

.watching-small-btn .watch-flag {
  width: 33px;
  height: 38px; }
  .watching-small-btn .watch-flag.active {
    color: #007fc1; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.sort-component {
  display: flex;
  user-select: none; }
  .sort-component .sort-dropdown {
    position: relative;
    margin-right: 10px; }
    .sort-component .sort-dropdown .dropdown-btn {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 231px;
      height: 44px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #000000;
      text-align: left;
      color: #030404;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: box-shadow 300ms;
      padding-left: 20px;
      text-decoration: none;
      overflow: hidden;
      user-select: none;
      margin: 0;
      padding-left: 10px;
      min-width: 240px; }
      .sort-component .sort-dropdown .dropdown-btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
      .sort-component .sort-dropdown .dropdown-btn .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        height: 100%;
        font-size: 22px;
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #000000;
        color: #fff; }
        .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-green {
          color: #1f9512 !important; }
        .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-success {
          background-color: #1f9512 !important; }
        .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-warning {
          background-color: #fac917 !important; }
        .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-danger {
          color: #dd2d2e !important; }
        .sort-component .sort-dropdown .dropdown-btn .icon-wrapper.icon-red {
          background-color: #dd2d2e !important; }
      .sort-component .sort-dropdown .dropdown-btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
      @media (max-width: 550px) {
        .sort-component .sort-dropdown .dropdown-btn {
          width: 100%; } }
      @media (min-width: 551px) {
        .sort-component .sort-dropdown .dropdown-btn:not(:last-of-type) {
          margin-right: 10px; } }
      .sort-component .sort-dropdown .dropdown-btn:hover:not(.btn-opened) {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
        .sort-component .sort-dropdown .dropdown-btn:hover:not(.btn-opened) .icon-wrapper {
          background-color: #fac917; }
      .sort-component .sort-dropdown .dropdown-btn .icon-wrapper {
        transition: 200ms;
        color: #5d5d5d; }
        @media (min-width: 550px) and (max-width: 900px) {
          .sort-component .sort-dropdown .dropdown-btn .icon-wrapper {
            margin-left: 10px; } }
      @media (min-width: 550px) {
        .sort-component .sort-dropdown .dropdown-btn-sm {
          max-width: 120px; }
        .sort-component .sort-dropdown .dropdown-btn-md {
          max-width: 155px; }
        .sort-component .sort-dropdown .dropdown-btn-lg {
          max-width: 230px; } }
      .sort-component .sort-dropdown .dropdown-btn-active:not(.btn-opened) {
        border-color: #1f9512; }
      .sort-component .sort-dropdown .dropdown-btn-active .icon-wrapper,
      .sort-component .sort-dropdown .dropdown-btn-active:hover .icon-wrapper {
        background-color: #1f9512;
        border-color: #1f9512; }
        .sort-component .sort-dropdown .dropdown-btn-active .icon-wrapper > svg,
        .sort-component .sort-dropdown .dropdown-btn-active .icon-wrapper > img,
        .sort-component .sort-dropdown .dropdown-btn-active:hover .icon-wrapper > svg,
        .sort-component .sort-dropdown .dropdown-btn-active:hover .icon-wrapper > img {
          filter: invert(100) brightness(200); }
  .sort-component .dropdown-choices {
    display: none;
    position: absolute;
    min-width: 240px;
    z-index: 100; }
    .sort-component .dropdown-choices .dropdown-choice {
      display: block;
      background-color: white;
      padding: 15px 5px;
      border: 1px solid #c6c6c6;
      font-family: Lato;
      font-size: 16px;
      text-align: center;
      cursor: pointer; }
      .sort-component .dropdown-choices .dropdown-choice:hover {
        background-color: #e1e1e1; }
      .sort-component .dropdown-choices .dropdown-choice:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
      .sort-component .dropdown-choices .dropdown-choice:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }
      .sort-component .dropdown-choices .dropdown-choice.selected {
        background-color: #cccccc; }
  .sort-component .sort-direction-btn {
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #c6c6c6;
    user-select: none;
    cursor: pointer; }
    .sort-component .sort-direction-btn .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-size: 22px;
      color: #5d5d5d; }
  .sort-component.opened .dropdown-choices {
    display: block; }

.filter-date-wrapper,
.filter-multi-wrapper,
.filter-range-wrapper {
  width: 100%;
  position: relative; }
  @media (min-width: 551px) {
    .filter-date-wrapper,
    .filter-multi-wrapper,
    .filter-range-wrapper {
      max-width: 231px;
      margin-right: 10px; } }
  .filter-date-wrapper .btn-opened,
  .filter-multi-wrapper .btn-opened,
  .filter-range-wrapper .btn-opened {
    position: relative;
    z-index: 900;
    border-color: #000;
    border-radius: 5px; }
    @media (min-width: 551px) {
      .filter-date-wrapper .btn-opened,
      .filter-multi-wrapper .btn-opened,
      .filter-range-wrapper .btn-opened {
        border-bottom: 0;
        border-radius: 5px 5px 5px 0; } }
    .filter-date-wrapper .btn-opened .icon-wrapper,
    .filter-multi-wrapper .btn-opened .icon-wrapper,
    .filter-range-wrapper .btn-opened .icon-wrapper {
      border-left: 1px solid #000;
      border-bottom: 1px solid #000; }

.filter-date-inner,
.filter-multi-inner,
.filter-range-inner {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 15px;
  background-color: #fff;
  font-family: Lato;
  border-radius: 5px;
  border: 1px solid #000;
  z-index: 99; }
  @media (min-width: 551px) {
    .filter-date-inner,
    .filter-multi-inner,
    .filter-range-inner {
      width: auto; } }
  @media (min-width: 1250px) {
    .filter-date-inner,
    .filter-multi-inner,
    .filter-range-inner {
      flex-direction: row;
      justify-content: space-between; } }
  .filter-date-inner > div:first-child,
  .filter-multi-inner > div:first-child,
  .filter-range-inner > div:first-child {
    text-align: center; }
    @media (min-width: 1250px) {
      .filter-date-inner > div:first-child,
      .filter-multi-inner > div:first-child,
      .filter-range-inner > div:first-child {
        text-align: left; } }
  .filter-date-inner.calendars-right-aligned,
  .filter-multi-inner.calendars-right-aligned,
  .filter-range-inner.calendars-right-aligned {
    left: auto;
    right: 0; }
    @media (min-width: 551px) {
      .filter-date-inner.calendars-right-aligned,
      .filter-multi-inner.calendars-right-aligned,
      .filter-range-inner.calendars-right-aligned {
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        right: 1px; } }
  .filter-date-inner h3, .filter-date-inner p,
  .filter-multi-inner h3,
  .filter-multi-inner p,
  .filter-range-inner h3,
  .filter-range-inner p {
    color: #5d5d5d;
    margin: 0; }
  .filter-date-inner h3,
  .filter-multi-inner h3,
  .filter-range-inner h3 {
    font-weight: 400;
    font-size: 18px;
    text-align: center; }
  .filter-date-inner p,
  .filter-multi-inner p,
  .filter-range-inner p {
    font-size: 14px; }
  .filter-date-inner .calendars-wrapper,
  .filter-multi-inner .calendars-wrapper,
  .filter-range-inner .calendars-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; }
    @media (min-width: 551px) {
      .filter-date-inner .calendars-wrapper,
      .filter-multi-inner .calendars-wrapper,
      .filter-range-inner .calendars-wrapper {
        flex-direction: row;
        align-items: flex-start; } }
    .filter-date-inner .calendars-wrapper.in-column,
    .filter-multi-inner .calendars-wrapper.in-column,
    .filter-range-inner .calendars-wrapper.in-column {
      flex-direction: column; }

.calendars-buttons,
.below-description {
  margin-bottom: 20px; }

.range-buttons {
  display: flex;
  justify-content: center; }
  .range-buttons .btn:first-child {
    margin-right: 10px; }

@media (min-width: 1250px) {
  .filter-date-inner {
    width: 873px; } }

.calendars-controls {
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 14px;
  color: #5d5d5d; }

.calendars-controls label,
.filter-multi-options label {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between; }
  .calendars-controls label span,
  .filter-multi-options label span {
    cursor: pointer; }

.calendars-controls .filter-checkbox,
.filter-multi-options .filter-checkbox {
  display: flex;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #000; }
  .calendars-controls .filter-checkbox.active,
  .filter-multi-options .filter-checkbox.active {
    background-color: #1f9512; }

.calendars-controls input,
.filter-multi-options input {
  display: none; }

.DayPicker-wrapper {
  font-family: Lato; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell] .DayPicker-Filled {
    border: 1px solid #464646;
    border-right: 0;
    border-bottom: 0; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role=gridcell]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day:not([role="gridcell"]) + .DayPicker-Day .DayPicker-Filled {
    border-top-left-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day[role="gridcell"]:first-of-type .DayPicker-Filled {
    border-top-left-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:first-child .DayPicker-Day[role="gridcell"]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
    border-top-right-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role="gridcell"] .DayPicker-Filled {
    border-bottom: 1px solid #464646; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role="gridcell"]:first-of-type .DayPicker-Filled {
    border-bottom-left-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week:last-child .DayPicker-Day[role="gridcell"]:last-of-type .DayPicker-Filled {
    border-right: 1px solid #464646;
    border-bottom-right-radius: 5px; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role="gridcell"] {
    position: relative;
    padding: 0;
    height: 33px;
    width: 33px; }
    .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role="gridcell"] .DayPicker-Filled {
      display: flex;
      width: 100%;
      height: 33px;
      justify-content: center;
      align-items: center;
      margin: 0; }
      .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day[role="gridcell"] .DayPicker-Filled.today {
        color: white;
        background-color: green;
        font-weight: bold; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--today {
    font-weight: inherit; }
    .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--today:not(.DayPicker-Day--disabled) {
      color: inherit; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover .DayPicker-Filled {
    background-color: #fac917; }
  .DayPicker-wrapper .DayPicker-Body .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) .DayPicker-Filled {
    background-color: #fac917;
    color: #fff; }

.DayPicker-Caption div {
  text-align: center; }

.DayPicker-wrapper .DayPicker-NavButton {
  height: 30px;
  width: 30px;
  top: 8px;
  border-radius: 3px;
  border: 1px solid #000;
  background-color: #c6c6c6;
  color: #fff; }
  .DayPicker-wrapper .DayPicker-NavButton--prev {
    left: 1rem;
    right: auto;
    margin-right: 0;
    background-image: url(assets/img/arrow-left.png); }
  .DayPicker-wrapper .DayPicker-NavButton--next {
    right: 1rem;
    background-image: url(assets/img/arrow-right.png); }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
@media (min-width: 551px) {
  .filter-multi-wrapper-sm,
  .filter-range-wrapper-sm {
    max-width: 120px; }
  .filter-multi-wrapper-md,
  .filter-range-wrapper-md {
    max-width: 155px; }
  .filter-multi-wrapper-lg,
  .filter-range-wrapper-lg {
    max-width: 230px; } }

.filter-multi-inner {
  min-width: 100%;
  flex-direction: column !important; }
  .filter-multi-inner.overflow {
    max-width: 500px;
    max-height: 500px;
    overflow: scroll;
    display: block; }
    @media screen and (max-width: 1300px) {
      .filter-multi-inner.overflow {
        min-width: 900px; } }
    @media screen and (max-width: 1010px) {
      .filter-multi-inner.overflow {
        min-width: 700px; } }
    @media screen and (max-width: 750px) {
      .filter-multi-inner.overflow {
        min-width: 400px; } }
    @media screen and (max-width: 420px) {
      .filter-multi-inner.overflow {
        min-width: 100%; } }
  @media (min-width: 550px) {
    .filter-multi-inner {
      border-top-left-radius: 0; } }
  .filter-multi-inner-right {
    left: auto;
    right: 0; }
    @media (min-width: 550px) {
      .filter-multi-inner-right {
        border-top-left-radius: 5px;
        border-top-right-radius: 0; } }
  @media (min-width: 1250px) {
    .filter-multi-inner {
      min-width: auto; } }
  .filter-multi-inner .filter-multi-options {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1250px) {
      .filter-multi-inner .filter-multi-options {
        flex-direction: row;
        justify-content: space-between; } }
  .filter-multi-inner .filter-multi-buttons {
    display: flex;
    justify-content: center; }
  .filter-multi-inner ul {
    display: flex;
    flex: 1;
    min-width: 200px;
    flex-direction: column; }
    @media (min-width: 1250px) {
      .filter-multi-inner ul:not(:last-child) {
        margin-right: 25px; } }

.filter-range-inner {
  flex-direction: column;
  width: 80vw;
  max-width: 824px; }
  .filter-range-inner.in-column {
    width: auto; }
    @media (max-width: 550px) {
      .filter-range-inner.in-column {
        width: 100%; } }
  @media (min-width: 550px) {
    .filter-range-inner {
      border-top-left-radius: 0; } }
  .filter-range-inner-right {
    left: auto;
    right: 0; }
    @media (min-width: 550px) {
      .filter-range-inner-right {
        border-top-left-radius: 5px;
        border-top-right-radius: 0; } }
  .filter-range-inner .slider-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 20px 0 30px;
    justify-content: center;
    align-items: center; }
    .filter-range-inner .slider-wrapper.in-column {
      flex-direction: column; }
    .filter-range-inner .slider-wrapper span {
      width: 120px;
      cursor: text;
      min-width: 110px; }
      .filter-range-inner .slider-wrapper span.slider-counter {
        max-width: 150px;
        display: inline-block;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 15px;
        border-radius: 5px;
        border: 1px solid #464646; }
        .filter-range-inner .slider-wrapper span.slider-counter-left {
          margin-bottom: 0;
          margin-right: 10px; }
          .filter-range-inner .slider-wrapper span.slider-counter-left.in-column {
            margin-bottom: 20px;
            margin-right: 0; }
        .filter-range-inner .slider-wrapper span.slider-counter-right {
          margin-top: 0;
          margin-left: 10px; }
          .filter-range-inner .slider-wrapper span.slider-counter-right.in-column {
            margin-top: 20px;
            margin-left: 0; }
      .filter-range-inner .slider-wrapper span input[type=number]::-webkit-inner-spin-button,
      .filter-range-inner .slider-wrapper span input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .filter-range-inner .slider-wrapper span input.weightFilter {
        width: 52%;
        min-width: 40px;
        float: left;
        margin-right: 3px;
        text-align: right;
        font-family: Lato;
        font-size: 17px;
        border-width: 0;
        color: #5d5d5d; }
      .filter-range-inner .slider-wrapper span input.weightSecondField {
        width: 52%;
        min-width: 56px;
        float: left;
        text-align: right;
        font-family: Lato;
        font-size: 17px;
        border-width: 0;
        color: #5d5d5d; }
      .filter-range-inner .slider-wrapper span .text-kg {
        font-family: Lato;
        color: #5d5d5d;
        font-size: 17px;
        text-align: left; }
  .filter-range-inner .rc-slider {
    flex: 1;
    max-width: 80%;
    margin: -7px 25px 0 25px; }
    .filter-range-inner .rc-slider-rail {
      height: 12px;
      background-color: #fff;
      border: 1px solid #000; }
    .filter-range-inner .rc-slider-track {
      height: 12px;
      background-color: #1f9512;
      border: 1px solid #000; }
    .filter-range-inner .rc-slider-handle {
      width: 40px;
      height: 24px;
      margin-top: -5px;
      margin-left: -20px;
      border-radius: 5px;
      border: 1px solid #000;
      background-color: #c6c6c6; }
    .filter-range-inner .rc-slider-handle-1:after, .filter-range-inner .rc-slider-handle-2:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      color: #000; }
    .filter-range-inner .rc-slider-handle-1:after {
      content: 'Min.'; }
    .filter-range-inner .rc-slider-handle-2:after {
      content: 'Max.'; }

.slider-wrapper {
  overflow: hidden; }
  .slider-wrapper.age {
    flex-direction: column; }
    .slider-wrapper.age .slider-wrapper-left, .slider-wrapper.age .slider-wrapper-right {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .slider-wrapper.age .slider-wrapper-left .slider-counter-age, .slider-wrapper.age .slider-wrapper-right .slider-counter-age {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 5px; }
        .slider-wrapper.age .slider-wrapper-left .slider-counter-age .inputAge, .slider-wrapper.age .slider-wrapper-right .slider-counter-age .inputAge {
          text-align: center; }
        .slider-wrapper.age .slider-wrapper-left .slider-counter-age .text-mounth, .slider-wrapper.age .slider-wrapper-right .slider-counter-age .text-mounth {
          width: 100%;
          min-width: auto;
          text-align: center; }

.slider-wrapper .range-age {
  margin-top: 15px;
  margin-bottom: 25px; }

.slider-wrapper .slider-wrapper-left,
.slider-wrapper .slider-wrapper-right {
  display: flex;
  flex: 0.3;
  margin-bottom: 10px; }
  .slider-wrapper .slider-wrapper-left .slider-text,
  .slider-wrapper .slider-wrapper-right .slider-text {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #5d5d5d;
    font-size: 18px; }
  .slider-wrapper .slider-wrapper-left span,
  .slider-wrapper .slider-wrapper-right span {
    width: 100%;
    cursor: text;
    min-width: 110px; }
    .slider-wrapper .slider-wrapper-left span.slider-counter-age:not(:root:root),
    .slider-wrapper .slider-wrapper-right span.slider-counter-age:not(:root:root) {
      line-height: 21px; }
    .slider-wrapper .slider-wrapper-left span.slider-counter-age,
    .slider-wrapper .slider-wrapper-right span.slider-counter-age {
      flex-direction: row;
      max-width: 75px;
      height: 60px;
      align-items: stretch;
      display: block;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px;
      border: 1px solid #464646; }
      .slider-wrapper .slider-wrapper-left span.slider-counter-age-left,
      .slider-wrapper .slider-wrapper-right span.slider-counter-age-left {
        margin-bottom: 0;
        margin-right: 0; }
        .slider-wrapper .slider-wrapper-left span.slider-counter-age-left.in-column,
        .slider-wrapper .slider-wrapper-right span.slider-counter-age-left.in-column {
          margin-bottom: 0;
          margin-right: 0; }
      .slider-wrapper .slider-wrapper-left span.slider-counter-age-right,
      .slider-wrapper .slider-wrapper-right span.slider-counter-age-right {
        margin-top: 0;
        margin-left: 0; }
        .slider-wrapper .slider-wrapper-left span.slider-counter-age-right.in-column,
        .slider-wrapper .slider-wrapper-right span.slider-counter-age-right.in-column {
          margin-top: 0;
          margin-left: 0; }
    .slider-wrapper .slider-wrapper-left span input,
    .slider-wrapper .slider-wrapper-right span input {
      width: 40%;
      min-width: 40px;
      margin-right: 3px;
      float: left;
      border-width: 0;
      color: #5d5d5d;
      font-size: 18px;
      text-align: right; }
    .slider-wrapper .slider-wrapper-left span .text-mounth,
    .slider-wrapper .slider-wrapper-right span .text-mounth {
      font-family: Lato;
      color: #5d5d5d;
      font-size: 18px;
      text-align: left; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.filter-kind-component {
  display: flex;
  user-select: none; }
  .filter-kind-component .filter-kind-dropdown {
    position: relative; }
    .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 231px;
      height: 44px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #000000;
      text-align: left;
      color: #030404;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: box-shadow 300ms;
      padding-left: 20px;
      text-decoration: none;
      overflow: hidden;
      user-select: none;
      margin: 0;
      padding-left: 10px;
      min-width: 240px; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        height: 100%;
        font-size: 22px;
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #000000;
        color: #fff; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-green {
          color: #1f9512 !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-success {
          background-color: #1f9512 !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-warning {
          background-color: #fac917 !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-danger {
          color: #dd2d2e !important; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper.icon-red {
          background-color: #dd2d2e !important; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
      @media (max-width: 550px) {
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn {
          width: 100%; } }
      @media (min-width: 551px) {
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:not(:last-of-type) {
          margin-right: 10px; } }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover:not(.btn-opened) {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.5); }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn:hover:not(.btn-opened) .icon-wrapper {
          background-color: #fac917; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
        transition: 200ms;
        color: #5d5d5d; }
        @media (min-width: 550px) and (max-width: 900px) {
          .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
            margin-left: 10px; } }
      @media (min-width: 550px) {
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-sm {
          max-width: 120px; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-md {
          max-width: 155px; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-lg {
          max-width: 230px; } }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:not(.btn-opened) {
        border-color: #1f9512; }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper,
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper {
        background-color: #1f9512;
        border-color: #1f9512; }
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper > svg,
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active .icon-wrapper > img,
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper > svg,
        .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn-active:hover .icon-wrapper > img {
          filter: invert(100) brightness(200); }
      .filter-kind-component .filter-kind-dropdown .filter-kind-dropdown-btn .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        height: 100%;
        font-size: 30px;
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #000000; }
  .filter-kind-component .dropdown-choices {
    display: none;
    position: absolute;
    min-width: 240px;
    z-index: 100; }
    .filter-kind-component .dropdown-choices .dropdown-choice {
      display: flex;
      background-color: white;
      padding: 15px 10px;
      justify-content: space-between;
      border: 1px solid #c6c6c6;
      font-family: Lato;
      font-size: 16px;
      cursor: pointer; }
      .filter-kind-component .dropdown-choices .dropdown-choice:hover {
        background-color: #e1e1e1; }
      .filter-kind-component .dropdown-choices .dropdown-choice:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
      .filter-kind-component .dropdown-choices .dropdown-choice:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }
      .filter-kind-component .dropdown-choices .dropdown-choice.selected {
        background-color: #cccccc; }
  .filter-kind-component.opened .dropdown-choices {
    display: block; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.watchlist-filters-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .watchlist-filters-wrapper > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (min-width: 550px) {
      .watchlist-filters-wrapper > div {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap; } }
  @media (min-width: 551px) {
    .watchlist-filters-wrapper .filter-range-wrapper-md {
      max-width: 166px; }
      .watchlist-filters-wrapper .filter-range-wrapper-md .filter-btn-md {
        max-width: 166px; }
    .watchlist-filters-wrapper .filter-multi-wrapper-md {
      max-width: 166px; }
      .watchlist-filters-wrapper .filter-multi-wrapper-md .filter-btn-md {
        max-width: 166px; }
    .watchlist-filters-wrapper .filter-btn:not(:last-of-type) {
      margin-right: 10px; } }

.watch-auction-info-top {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  background-color: #007fc1;
  margin: 25px 0 13px; }
  @media (min-width: 550px) {
    .watch-auction-info-top {
      flex-direction: row;
      align-items: center;
      padding: 3px 0; } }
  .watch-auction-info-top > div:not(.info-btns) {
    display: flex;
    flex: 1;
    align-self: stretch;
    margin-bottom: 15px; }
    @media (min-width: 550px) {
      .watch-auction-info-top > div:not(.info-btns) {
        margin-bottom: 0; } }
  .watch-auction-info-top .watch-auction-kind {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding-left: 12px; }
    @media (min-width: 550px) {
      .watch-auction-info-top .watch-auction-kind {
        min-width: 115px;
        padding-left: 0;
        border-right: 1px solid #fff; } }
    .watch-auction-info-top .watch-auction-kind .cattle-icon {
      filter: invert(100%) brightness(200%); }
  .watch-auction-info-top .watch-auction-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px; }
    .watch-auction-info-top .watch-auction-info h3 {
      color: #ffffff;
      font-family: Lato;
      font-size: 30px;
      font-weight: 400;
      margin: 0 0 12px; }
    .watch-auction-info-top .watch-auction-info p {
      color: #efefef;
      font-family: Lato;
      font-size: 20px;
      font-weight: 400;
      margin: 0; }
  .watch-auction-info-top .info-btns {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 0; }
    @media (min-width: 1250px) {
      .watch-auction-info-top .info-btns {
        flex-direction: row; } }
    .watch-auction-info-top .info-btns a {
      text-decoration: none; }
    .watch-auction-info-top .info-btns .btn {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 231px;
      height: 44px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #000000;
      text-align: left;
      color: #030404;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: box-shadow 300ms;
      padding-left: 20px;
      text-decoration: none;
      overflow: hidden;
      user-select: none;
      border-color: #000; }
      .watch-auction-info-top .info-btns .btn:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.16), 0 0 5px rgba(0, 0, 0, 0.3); }
      .watch-auction-info-top .info-btns .btn .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        height: 100%;
        font-size: 22px;
        border-radius: 5px 0 0 5px;
        border-left: 1px solid #000000;
        color: #fff; }
        .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-green {
          color: #1f9512 !important; }
        .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-success {
          background-color: #1f9512 !important; }
        .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-warning {
          background-color: #fac917 !important; }
        .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-danger {
          color: #dd2d2e !important; }
        .watch-auction-info-top .info-btns .btn .icon-wrapper.icon-red {
          background-color: #dd2d2e !important; }
      .watch-auction-info-top .info-btns .btn.filter-btn-md {
        max-width: 166px; }
      .watch-auction-info-top .info-btns .btn .icon-wrapper {
        border-color: #000; }
      .watch-auction-info-top .info-btns .btn.watch-enter-btn {
        margin-bottom: 10px;
        background-color: #fac917;
        color: #fff; }
        @media (min-width: 1250px) {
          .watch-auction-info-top .info-btns .btn.watch-enter-btn {
            margin-bottom: 0;
            margin-right: 7px; } }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.lot-documents {
  margin: 20px 0; }
  .lot-documents .option-value {
    opacity: 0.5;
    cursor: pointer; }
    .lot-documents .option-value:hover {
      opacity: 1; }

.empty-documents-wrapper {
  padding-left: 10px; }
  .empty-documents-wrapper span {
    width: 50%;
    display: inline-block; }

/* this mixin creates a container capable of holding header, content and footer
  make sure to give content flex-grow: 1 */
/* header at the top that is used in a number of screens
 used to display basic Auction data */
.download-wrapper {
  position: relative;
  margin-right: 10px;
  width: 231px; }
  @media (max-width: 550px) {
    .download-wrapper {
      width: 100%; } }
  .download-wrapper .btn.download-btn {
    margin: 0 10px 10px 0; }
  .download-wrapper .inner-wrapper {
    font-family: Lato;
    position: absolute;
    top: 44px;
    background: white;
    padding: 15px 10px 5px 20px;
    width: 100%;
    border: 1px solid black;
    border-top: none;
    display: none;
    z-index: 1; }
    .download-wrapper .inner-wrapper.active {
      display: block; }
    .download-wrapper .inner-wrapper .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      color: #030404;
      text-decoration: none; }
      .download-wrapper .inner-wrapper .item .icon-wrapper {
        font-size: 20px;
        color: #adb5bd; }
