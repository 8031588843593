@import "../../themes/general/variables.scss";

.calculator {
  width: 310px !important;
  margin-left: 20px !important;
  border: 1px solid !important;
  height: 220px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background: #fff !important;
  @media (max-width: $medium-width) {
    margin-left: 0 !important;
    width: 300px !important;
  }
  .logo {
    background: url('../../assets/img/stock-co-logo.svg') no-repeat;
    width: 180px;
    height: 38px;
    margin-top: 10px;
  }
  .text {
    width: 180px;
    text-align: justify;
    font: 400 14px Lato;
  }
  .btn {
    font: 400 14px Lato;
    height: 30px !important;
    width: 160px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 0 !important;
    background: #fff !important;
    border: 1px solid !important;
    text-decoration: none !important;
    color: black !important;
    &:hover {
      cursor: pointer;
    }
    .button-txt {
      width: 100% !important;
      text-align: center !important;
    }
    .icon {
      background-color: #fac917;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      border-top: 1px solid;
      border-bottom: 1px solid;
      font-size: 19px;
    }
  }
  .btn:hover {
    cursor: pointer;
  }
}